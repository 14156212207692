//
// Fonts
// --------------------------------------------------
body {
	//font-weight: 300;
	//-webkit-hyphens: auto;
	//-moz-hyphens: auto;
	//-o-hyphens: auto;
	//-ms-hyphens: auto;
	//hyphens: auto;
}



//
// Headings
// --------------------------------------------------
header {
	> div.subheader {
		font-size: $font-size-large;
		letter-spacing: 2px;
		font-weight: bold;
		magin-bottom: 8px;
		p {
			margin: 0;
		}
	}
	h1.has-subheader,
	h2.has-subheader,
	h3.has-subheader,
	h4.has-subheader,
	h5.has-subheader,
	h6.has-subheader {
		margin-bottom: 0;
		padding-bottom: 2px;
	}
	> div.subheader {
		padding-bottom: 15px;
	}
}

h1, .h1 {
    //font-weight: 500;
}

h3, .h3 {
    margin-bottom: 1rem;
}

h2 span.headline-bigger {

}

//@media (max-width: @screen-xs-max) {
@include media-breakpoint-down(sm) {
	h1 { font-size: $font-size-large-4 };
	h2 { font-size: $font-size-large-2 };
	h4 { font-size: $font-size-large };
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
    hyphens: auto;
    -webkit-hyphenate-limit-before: 3; /* For Safari */
    -webkit-hyphenate-limit-after: 4; /* For Safari */
    -ms-hyphenate-limit-chars: 10 3 4;
    hyphenate-limit-chars: 10 3 4;
    word-wrap: break-word;
    overflow-wrap: break-word;
}


//
// Body text
// --------------------------------------------------
p {
	margin-bottom: 20px;
}

.hyphens {
    hyphens: auto;
}

p.headline {
	margin-bottom: 15px !important;
	font-size: $h3-font-size;
	text-transform: uppercase;
	line-height: $headings-line-height;
	font-weight: 400;
}

.subliner {
	color: $text-color-gray-light;
	font-weight: 700;
	margin-bottom: 40px;
	letter-spacing: 2px;
	text-transform: uppercase;

}


b,
strong {
	font-weight: 700;
}

.ce-bodytext {
	h3 {
		//letter-spacing: 2px;
		font-size: $font-size-large-1;
	}
}

.text-light {
	color: $text-color-lighten;
}

.text-dark {
	color: $text-color;
}

.font-weight-base {
    font-weight: $font-weight-base !important;
}
.font-weight-lighter {
    font-weight: $font-weight-lighter !important;
}
.font-weight-light {
    font-weight: $font-weight-light !important;
}
.font-weight-normal {
    font-weight: $font-weight-normal !important;
}
.font-weight-bold {
    font-weight: $font-weight-bold !important;
}
.font-weight-bolder {
    font-weight: $font-weight-bolder !important;
}
.font-weight-semibold {
    font-weight: 600 !important;
}
.font-weight-medium {
    font-weight: 500 !important;
}

.line-height-small {
    line-height: 1.2;
}
