.ids-blog {
    .sticky-menu {
        position: sticky;
        top: 160px;
    }
    //.margin-top-1 { // default
    //    @extend .mt-12;
    //}
    //.margin-top-2 {
    //    @extend .mt-4;
    //}
    //.margin-top-3 {
    //    @extend .mt-8;
    //}
    //.margin-top-4 {
    //    @extend .mt-12;
    //}
    //.margin-top-5 {
    //    @extend .mt-16;
    //}
    //.margin-top-6 {
    //    @extend .mt-20;
    //}
    //.margin-bottom-1 {
    //    @extend .mb-16;
    //}
    .margin-bottom-2 {
        @extend .mb-6;
    }
    .margin-bottom-3 {
        @extend .mb-12;
    }
    .margin-bottom-4 {
        @extend .mb-18;
    }
    .margin-bottom-5 {
        @extend .mb-24;
    }
    .margin-bottom-6 {
        @extend .mb-28;
    }

    .blog-menu {
        a.active,
        a:hover {
            color: $primary !important;
        }
    }

    .blog-date-col {
        width: 125px;
        @include media-breakpoint-down(lg) {
            width: 100px;
        }
        //@include media-breakpoint-down(sm) {
        //    width: 100%;
        //}
    }
    .blog-maincontent {
        .centered-element {
            .blog-image {
                @extend .pe-8;
            }
        }
    }

    .news-list-view-101 {
        span.info-bg {
            position: absolute;
            z-index: 9;
            top: 24px;
            left: 24px;
        }
        .news-list-view-teaserinfo {
            p {
                margin-bottom: 0;
            }
        }
        .news-list-item {
            background-color: $gray-lighter;
            background-repeat: no-repeat;
            background-position: right;
            background-size: cover;
            //height: 450px;
            overflow: hidden;
            a {
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                .news-list-item-bg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: rgba(42,43,47,0);
                    z-index: 0;
                    @include transition-theme-2(background-color, $teaser-main-duration-time, ease, $teaser-main-transition-time);
                }
                .news-list-item-content {
                    padding: 0 25px;
                    position: absolute;
                    bottom: 0;
                    height: 100px;
                    width: 100%;
                    z-index: 1;
                    h1, h2, h3, h4, h5,
                    h1 a, h2 a, h3 a, h4 a, h5 a {
                        color: $text-color-lighten;
                        font-size: $font-size-large-3;
                    }

                    p {
                        -webkit-transform: translate3d(0,0,0);
                        transform: translate3d(0,0,0);
                        font-size: $font-size-large;
                        color: #fff;

                        @include transition-theme-2(all, $teaser-main-duration-time, ease, $teaser-main-transition-time);
                        &.news-title {
                            font-size: $font-size-large-1;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin-bottom: 0;
                            a {
                                color: #fff;
                            }
                        }
                        &.news-divider {
                            display: block;
                            width: 50px;
                            margin-bottom: 15px;
                            padding-bottom: 15px;
                            border-bottom: 2px solid $primary;
                            height: 1px;
                        }
                    }
                    div.news-teaser {
                        -webkit-transform: translate3d(0,0,0);
                        transform: translate3d(0,0,0);
                        font-size: $font-size-large;
                        color: #fff;
                        opacity: 0;
                        @include transition-theme-2(all, $teaseropacity-main-duration-time, ease, $teaseropacity-main-transition-time);
                    }
                }
                &:after {
                    //content: ' ';
                    //position: absolute;
                    //width: 100%;
                    //height: 100%;
                    //top: 0;
                    //left: 0;
                    //background-color: rgba(42,43,47,0.50);
                    //z-index: 0;
                }
                &:hover {
                    .news-list-item-bg {
                        background-color: rgba(42,43,47,0.75);
                        @include transition-theme-2(background-color, $teaser-main-duration-time, ease, $teaser-main-transition-time);
                    }
                    .news-list-item-content {
                        p {
                            -webkit-transform: translate3d(0,-140px,0);
                            transform: translate3d(0,-140px,0);
                            @include transition-theme-2(all, $teaser-main-duration-time, ease, $teaser-main-transition-time);
                            //$media (max-width: $screen-lg) {
                            @include media-breakpoint-down(xl) {
                                -webkit-transform: translate3d(0,-100px,0);
                                transform: translate3d(0,-100px,0);
                            }
                            //$media (max-width: $screen-md) {
                            @include media-breakpoint-down(lg) {
                                -webkit-transform: translate3d(0,-200px,0);
                                transform: translate3d(0,-200px,0);
                            }
                            //$media (max-width: $screen-sm) {
                            @include media-breakpoint-down(md) {
                                -webkit-transform: translate3d(0,-150px,0);
                                transform: translate3d(0,-150px,0);
                            }
                            //$media (max-width: $screen-xs) {
                            @include media-breakpoint-down(sm) {
                                -webkit-transform: translate3d(0,-80px,0);
                                transform: translate3d(0,-80px,0);
                            }
                            &.news-divider {
                                opacity: 1;
                                @include transition-theme-2(all, $teaseropacity-main-duration-time, ease, $teaseropacity-main-transition-time);
                            }
                        }
                        div.news-teaser {
                            opacity: 1;
                            @include transition-theme-2(all, $teaseropacity-main-duration-time, ease, $teaseropacity-main-transition-time);
                        }
                    }
                }
            }
        }
    }
}
