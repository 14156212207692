.page-header {
    z-index: 9999;
    .navbar-brand {
        padding: 1.22rem 0;
        @include media-breakpoint-down(xl) {
            padding: 0.76rem 0;
        }
    }
    nav {
        ul,
        ul li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }
    .mainnavigation-top {
        ul {
            li {
                a {
                    padding: 1.8rem 0 1.6rem 0;
                    border-bottom: 5px solid transparent;
                    &.active,
                    &:hover {
                        border-color: $primary;
                    }
                }
                //&.active {
                //    > a {
                //        border-color: $primary;
                //    }
                //}
                .mainnavigation-sub {
                    display: none;
                    top: 100%;
                    box-shadow: inset 0px 10px 14px -10px $gray-900;
                    &.show {
                        display: block;
                    }

                    .mainnavigation-sub-wrapper {
                        position: relative;
                        left: 150px;
                        @include media-breakpoint-down(xl) {
                            left: 72px;
                        }
                        &.show-border.active {
                            border-right: 1px solid $gray-200;
                        }
                        ul {
                            display: inline-flex;
                            flex-direction: column;

                            li {
                                @include media-breakpoint-up(xl) {
                                    a {
                                        padding: 0.5rem 2rem;
                                        border: 0;
                                        &.active,
                                        &:hover {
                                            background-color: $gray-900;
                                        }
                                    }
                                    .mainnavigation-sub-child-wrapper {
                                        position: absolute;
                                        left: 100%;
                                        top: 0;
                                        opacity: 0;
                                        visibility: hidden;
                                        margin-left: 1px;

                                        &.fade-in {
                                            opacity: 1;
                                            visibility: visible;
                                            animation-name: fadeInOpacity;
                                            animation-iteration-count: 1;
                                            animation-timing-function: ease-in;
                                            animation-duration: .5s;
                                        }
                                    }
                                    &:hover {
                                        .mainnavigation-sub-child-wrapper {

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(xl) {
        .page-navigation {
            .mainnavigation-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                background: $gray-800;
                opacity: 0.85;
            }
            .navbar-toggle {
                display: flex;
                margin: 1rem 0;
                float: right;
                //width: 51px;
                //height: 51px;
                //background-image: url(../Images/menu-hamburger.png);
                //background-repeat: no-repeat;
                //background-position: center;
                //background-size: cover;
                //background-color: transparent !important;
                //text-indent: -999em;
                //border: 0;
                position: relative;
                z-index: 999;
                &:not(.collapsed) {
                    margin-right: $grid-gutter-width/2;
                    i:before {
                        content: '\f00d';
                    }
                }
                &:hover {
                    //background-image: url(../Images/menu-hamburger-active.png);
                    //background-color: inherit;
                }
                //$media(max-width: $screen-sm-max) {
                @include media-breakpoint-down(xl) {
                    //width: 38px;
                    //height: 38px;
                }
            }
            .mainnavigation-top.navbar-collapse {
                position: fixed;
                top: 0;
                height: 100vh;
                padding-top: 3.5rem;
                padding-bottom: 7rem;
                padding-left: 10%;
                //padding-bottom: 40%;
                background: $gray-800;
                right: 0;
                width: 80%;
                overflow-y: scroll;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
                ul {
                    flex-direction: column;
                    li {
                        a {
                            padding: 22px 18px;
                            line-height: 1;
                            font-size: $font-size-large;
                            text-transform: uppercase;
                            white-space: nowrap;
                            border: 0;
                            font-size: 1.125rem;
                            font-weight: 700;
                            //@media(max-width: 1300px) {
                            //    padding-top: 22px;
                            //    font-size: $font-size-base;
                            //}
                            //@media(max-width: 1200px) {
                            //    padding-top: 22px;
                            //    font-size: $font-size-sm;
                            //}
                            //@media(max-width: 1100px) {
                            //    padding-top: 22px;
                            //    font-size: $font-size-sm;
                            //    padding-left: 30px;
                            //    padding-right: 30px;
                            //}

                            &:hover {

                            }
                        }
                        &.active {
                            > a {
                            }
                        }
                        div.mainnavigation-sub {
                            //display: block;
                            top: inherit;
                            position: inherit !important;
                            box-shadow: none;
                            margin-left: 3rem;
                            width: auto !important;
                            .container-fluid {
                                max-width: none;
                                .mainnavigation-sub-wrapper {
                                    left: 0;
                                }
                            }
                            .mainnavigation-sub-wrapper {
                                position: relative;
                                left: inherit;
                                &.active {
                                    border-right: 0;
                                }
                                ul {
                                    display: block;
                                    //flex-direction: column;
                                    width: 100%;
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    border-radius: 0;
                                    padding: 20px 0;
                                    @include media-breakpoint-down(sm) {
                                        width: 100%;
                                        left: 0;
                                    }
                                    @include media-breakpoint-down(xl) {
                                        border: 0 !important;
                                        padding-top: 0;
                                    }
                                    li {
                                        a {
                                            text-transform: none;
                                            padding: 5px;
                                            font-size: 1rem !important;
                                            font-weight: 600;
                                            white-space: normal;
                                            //line-height: 1.2;
                                            &:hover {
                                            }
                                        }
                                        &.active {
                                            a {
                                                //background-color: unset;
                                            }
                                        }
                                        .mainnavigation-sub-child-wrapper {
                                            margin-left: 2rem;
                                            ul {
                                                li {
                                                    a {
                                                        font-size: 0.875rem !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        ul {

                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


//.page-header {
//	position: fixed;
//	top: 0;
//	left: 0;
//	width: 100%;
//	margin: 0;
//	padding-bottom: 0;
//	z-index: 9999;
//	//@media(max-width: 1600px) {
//	//	padding-top: 26px;
//	//}
//    //@media(max-width: 1300px) {
//    //    padding-top: 20px;
//    //}
//    //@media(max-width: 1200px) {
//    //    padding-top: 20px;
//    //}
//    //@media(max-width: 1100px) {
//    //    padding-top: 20px;
//    //}
//	//$media(max-width: $screen-sm-max) {
//    @include media-breakpoint-down(md) {
//		//position: relative;
//		padding-top: 0;
//		background: none;
//		border: 0;
//	}
//	.container {
//		//width: 100%;
//		//max-width: 1920px;
//		padding: 0;
//	}
//	> .container {
//		position: relative;
//	}
//	.navbar-header-wrapper {
//		//position: relative;
//        .navbar {
//            position: inherit !important;
//        }
//		.navbar-header {
//			padding: 0;
//            padding-right: ($grid-gutter-width/2);
//			//$media(max-width: $screen-sm-max) {
//            @include media-breakpoint-down(md) {
//				background-color: $gray-dark;
//				padding-top: 6px;
//			}
//			.navbar-brand {
//				height: auto;
//				margin-top: 4px;
//                width: 180px;
//				//$media(max-width: $screen-sm-max) {
//                @include media-breakpoint-down(xl) {
//					width: 160px;
//				}
//			}
//			.navbar-toggle {
//				display: block;
//				width: 51px;
//				height: 51px;
//				background-image: url(../Images/menu-hamburger.png);
//				background-repeat: no-repeat;
//				background-position: center;
//				background-size: cover;
//                background-color: transparent !important;
//				text-indent: -999em;
//				border: 0;
//				&:hover {
//					background-image: url(../Images/menu-hamburger-active.png);
//					background-color: inherit;
//				}
//				//$media(max-width: $screen-sm-max) {
//                @include media-breakpoint-down(xl) {
//					width: 38px;
//					height: 38px;
//				}
//			}
//		}
//		.navbar-collapse {
//			margin-left: 0px;
//			float: left;
//			//$media(max-width: $screen-sm-max) {
//            @include media-breakpoint-down(xl) {
//				position: fixed;
//				height: 100%;
//                top: 51px;
//				right: 0;
//				width: 75%;
//				background-color: #fff;
//				overflow-y: scroll;
//				padding-bottom: 40%;
//				border-left: 2px solid $gray-lighter;
//				padding-left: 0;
//				padding-right: 0;
//			}
//			//$media(max-width: $screen-xs-max) {
//            @include media-breakpoint-down(sm) {
//				width: 100%;
//				left: 0;
//                border: 0 !important;
//			}
//			ul {
//				&.navbar-nav {
//					//$media(max-width: $screen-sm-max) {
//                    @include media-breakpoint-down(md) {
//						margin: 0;
//					}
//				}
//				li {
//					line-height: 1;
//					position: inherit;
//					a {
//						padding: 22px 18px;
//						line-height: 1;
//						font-size: $font-size-large;
//						text-transform: uppercase;
//                        white-space: nowrap;
//						@media(max-width: 1300px) {
//							padding-top: 22px;
//							font-size: $font-size-base;
//						}
//						@media(max-width: 1200px) {
//							padding-top: 22px;
//							font-size: $font-size-sm;
//						}
//						@media(max-width: 1100px) {
//							padding-top: 22px;
//							font-size: $font-size-sm;
//							padding-left: 30px;
//							padding-right: 30px;
//						}
//						//$media(max-width: $screen-sm-max) {
//                        @include media-breakpoint-down(xl) {
//							color: $text-color !important;
//                            font-size: 1.5rem;
//                            font-weight: 700;
//							b.caret {
//								border-left: 4px dashed;
//								border-left: 4px solid \9;
//								border-top: 4px solid transparent;
//								border-bottom: 4px solid transparent;
//							}
//						}
//
//						&:hover {
//							background-color: $primary;
//							//$media(max-width: $screen-sm-max) {
//                            @include media-breakpoint-down(xl) {
//                                color: #fff !important;
//
//							}
//						}
//					}
//					&.active {
//						> a {
//							background-color: $primary;
//							//$media(max-width: $screen-sm-max) {
//                            @include media-breakpoint-down(xl) {
//								color: #fff !important;
//							}
//						}
//					}
//					ul {
//						width: 100%;
//						margin-top: 0;
//						margin-bottom: 0;
//						border-radius: 0;
//						padding: 20px 0;
//						//$media(max-width: $screen-sm-max) {
//                        //@include media-breakpoint-down(xl) {
//						//	position: fixed;
//						//	top: 71px;
//						//	left: 25%;
//						//	width: 75%;
//						//	background-color: #fff;
//						//	height: 100%;
//						//	overflow: scroll;
//						//	padding-bottom: 40%;
//						//	margin: 0;
//						//	border-left: 2px solid $gray-lighter;
//						//}
//						//$media(max-width: $screen-xs-max) {
//                        @include media-breakpoint-down(sm) {
//							width: 100%;
//							left: 0;
//						}
//                        @include media-breakpoint-down(xl) {
//                            border: 0 !important;
//                            padding-top: 0;
//                        }
//						li {
//							float: left;
//							position: relative;
//							border-right: 1px solid $gray-lighter;
//							&:last-of-type {
//								border: 0;
//							}
//							//$media(max-width: $screen-sm-max) {
//                            @include media-breakpoint-down(xl) {
//								//float: none;
//								//display: block;
//								border: 0;
//								//width: 100%;
//                                flex: 0 0 auto;
//							}
//							a {
//								text-transform: none;
//								padding: 5px;
//								text-align: center;
//								letter-spacing: 0;
//								font-size: $font-size-base;
//								&:hover {
//									background-color: inherit;
//									color: $primary;
//								}
//								//$media(max-width: $screen-sm-max) {
//                                @include media-breakpoint-down(xl) {
//									text-align: left;
//									font-size: 1rem;
//                                    //text-transform: uppercase;
//									padding: 10px 55px;
//									&:hover {
//										background: none;
//									}
//								}
//								.navbar-main-image {
//									margin-bottom: 10px;
//									//$media(max-width: $screen-sm-max) {
//                                    @include media-breakpoint-down(xl) {
//										display: none;
//									}
//								}
//								.navbar-main-title {
//									white-space: normal;
//									text-transform: uppercase;
//									line-height: 1.2;
//									//$media(max-width: $screen-sm-max) {
//                                    @include media-breakpoint-down(xl) {
//										color: $text-color;
//										font-weight: 300;
//                                        text-transform: none;
//									}
//								}
//							}
//							//$media(max-width: $screen-sm-max) {
//                            //@include media-breakpoint-down(xl) {
//							//	&:not(:last-of-type) {
//							//		a {
//							//			border-bottom: 2px solid $gray-lighter;
//							//		}
//							//	}
//							//}
//							&.active {
//								a {
//									background: none;
//									color: $primary;
//									//$media(max-width: $screen-sm-max) {
//                                    @include media-breakpoint-down(xl) {
//										background-color: $primary;
//										color: #fff;
//									}
//								}
//							}
//						}
//						div.navbar-main-backlink {
//							font-weight: 700;
//							font-size: $font-size-large-1;
//							letter-spacing: 2px;
//							padding-bottom: 25px;
//							margin-bottom: 10px;
//							border-bottom: 2px solid $gray-lighter;
//							float: none;
//							span.backlink {
//								color: $text-color;
//								font-size: $font-size-large-1;
//								cursor: pointer;
//							}
//						}
//						div.navbar-main-parentlink {
//							font-weight: 700;
//							font-size: $font-size-large-1;
//							letter-spacing: 2px;
//							padding-top: 25px;
//							padding-bottom: 25px;
//							margin-bottom: 10px;
//							border-bottom: 2px solid $gray-lighter;
//							//$media(max-width: $screen-sm-max) {
//                            @include media-breakpoint-down(xl) {
//								//padding-left: 0;
//								//padding-right: 0;
//								padding-top: 0;
//								padding-bottom: 0;
//								font-weight: 300;
//								color: $text-color;
//								letter-spacing: 0;
//								float: none;
//							}
//							a {
//
//								padding-bottom: 25px;
//								&:hover {
//									background: none;
//									//padding: 0;
//								}
//								//$media(max-width: $screen-sm-max) {
//                                @include media-breakpoint-down(xl) {
//									display: block;
//									padding-top: 25px;
//									&:hover {
//										padding-top: 25px;
//										padding-bottom: 25px;
//										padding-left: 10px;
//										padding-right: 10px;
//										background-color: $primary;
//									}
//								}
//							}
//						}
//						div.navbar-main-infolink {
//							margin-top: 10px;
//							padding-top: 25px;
//							border-top: 2px solid $gray-lighter;
//							.container {
//								width: inherit;
//							}
//							a {
//								color: $gray-dark !important;
//								&:hover {
//									background: none;
//									color: $primary !important;
//								}
//							}
//							p {
//								padding: 0;
//								margin: 0;
//							}
//						}
//					}
//				}
//			}
//		}
//	}
//	nav.infonavigation {
//		//position: absolute;
//		//right: 0;
//		//top: 0;
//		//@media(max-width: 1600px) {
//		//	position: absolute;
//		//	top: -26px;
//		//	right: 0;
//		//	width: 100%;
//		//	background-color: $gray-darker;
//		//}
//		//@media(max-width: 1300px) {
//        //    top: -20px;
//        //}
//
//		ul {
//			list-style: none;
//			margin: 0;
//			padding: 0;
//			float: right;
//			li {
//				list-style: none;
//				margin: 0;
//				padding: 0;
//				line-height: 1;
//				float: left;
//				a {
//					display: block;
//					padding: 22px 20px;
//					line-height: 1;
//					font-size: $font-size-large;
//					text-transform: uppercase;
//					color: #fff;
//
//
//					&:hover {
//						background-color: $primary;
//
//					}
//					@media(max-width: 1300px) {
//
//						font-size: $font-size-sm;
//					}
//					@media(max-width: 1200px) {
//
//						font-size: $font-size-small-1;
//					}
//					@media(max-width: 1100px) {
//
//						font-size: $font-size-small-1;
//
//					}
//					//$media(max-width: $screen-sm-max) {
//                    @include media-breakpoint-down(xl) {
//						color: $text-color;
//						font-size: $font-size-large;
//					}
//				}
//				@media(max-width: 1600px) {
//					a {
//						padding: 4px 20px;
//						color: #fff;
//					}
//				}
//			}
//		}
//	}
//
//    ul.navbar-nav {
//        .font-size-larger-lg-1 {
//            font-size: 1rem !important;
//        }
//    }
//}
