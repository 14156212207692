//
// Body
// --------------------------------------------------
html {
    height: 100%;
    //@media(max-width: 1024px) {
    //    font-size: .81rem;
    //}
    //@media(max-width: 767px) {
    //    font-size: 1rem;
    //}
}


body {
  &.no-scrolling {
		overflow: hidden;
	}
}

@include media-breakpoint-up(md) {
    .container-fluid {
        padding: 0;
        //@media(min-width: @screen-lg-min) {
        max-width: calc(map-get($container-max-widths, 'xxl') - $sideBarWidth - $sideBarMargin);
        @include media-breakpoint-down(xxl) {
            max-width: calc(map-get($container-max-widths, 'xl') - $sideBarWidth-xl - $sideBarMargin-xl);
        }
        @include media-breakpoint-down(xl) {
            max-width: calc(map-get($container-max-widths, 'lg') - $sideBarMargin-lg);
        }
        @include media-breakpoint-down(lg) {
            max-width: calc(map-get($container-max-widths, 'md') - $sideBarMargin-md);
        }
        @include media-breakpoint-down(md) {
            max-width: calc(map-get($container-max-widths, 'sm') - $sideBarMargin-sm);
        }
        margin: 0;

        &.container-fluid-wide {
            max-width: none !important;
            padding-right: 0 !important;
        }

        //@include media-breakpoint-down(xl) {
        //    padding-left: 25px;
        //    padding-right: 25px;
        //}
    }
}

.container-wrapper {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: none;
    margin-left: calc((100% - map-get($container-max-widths, 'xxl')) / 2);
    @include media-breakpoint-down(xxl) {
        margin-left: calc((100% - map-get($container-max-widths, 'xl')) / 2);
    }
    @include media-breakpoint-down(xl) {
        margin-left: calc((100% - map-get($container-max-widths, 'lg')) / 2);
    }
    @include media-breakpoint-down(lg) {
        margin-left: calc((100% - map-get($container-max-widths, 'md')) / 2);
    }
    @include media-breakpoint-down(md) {
        //margin-left: calc((100% - map-get($container-max-widths, 'sm')) / 2);
        margin-left: 0;
    }
    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }
    .sidebar {
        width: $sideBarWidth;
        max-width: $sideBarWidth;
        @include media-breakpoint-down(xxl) {
            width: $sideBarWidth-xl;
            max-width: $sideBarWidth-xl;
        }
        @include media-breakpoint-down(xl) {
            width: $sideBarWidth-lg;
            max-width: $sideBarWidth-lg;
        }
        @include media-breakpoint-down(lg) {
            width: $sideBarWidth-md;
            max-width: $sideBarWidth-md;
        }
        @include media-breakpoint-down(md) {
            width: $sideBarWidth-sm;
            max-width: $sideBarWidth-sm;
        }
    }
    .maincontent {
        flex: 1 0 0;
        width: auto;
        max-width: 100%;
        margin-left: $sideBarMargin;
        &.no-sidebar {
            margin-left: $sideBarWidth;
        }
        @include media-breakpoint-down(xxl) {
            margin-left: $sideBarMargin-xl;
            &.no-sidebar {
                margin-left: $sideBarWidth-xl;
            }
        }
        @include media-breakpoint-down(xl) {
            margin-left: $sideBarMargin-lg;
            &.no-sidebar {
                margin-left: $sideBarWidth-lg;
            }
        }
        @include media-breakpoint-down(lg) {
            margin-left: $sideBarMargin-md;
            &.no-sidebar {
                margin-left: $sideBarWidth-md;
            }
        }
        @include media-breakpoint-down(md) {
            margin-left: $sideBarMargin-sm;
            &.no-sidebar {
                margin-left: $sideBarWidth-sm;
            }
        }
        @include media-breakpoint-down(sm) {
            margin-left: 0;
            &.no-sidebar {
                margin-left: 0;
            }
        }
    }
    &.maincontent-wrapper {
        margin-top: 11.125rem;
        @include media-breakpoint-down(xl) {
            margin-top: 7.125rem;
        }
        @include media-breakpoint-up(xl) {
            .maincontent {
                //margin-top: 4rem;
            }
        }
    }
    &.container-wrapper-single {
        .maincontent {
            margin-top: 0;
            margin-left: 0;
        }
        .container-fluid {
            max-width: map-get($container-max-widths, 'xxl');
            @include media-breakpoint-down(xxl) {
                max-width: map-get($container-max-widths, 'xl');
            }
            @include media-breakpoint-down(xl) {
                max-width: map-get($container-max-widths, 'lg');
            }
            @include media-breakpoint-down(lg) {
                max-width: map-get($container-max-widths, 'md');
            }
            @include media-breakpoint-down(md) {
                //max-width: map-get($container-max-widths, 'sm');
                max-width: none;
            }
            @include media-breakpoint-down(sm) {
                max-width: none;
            }
        }
    }
    &.maincontent2ColIndented-wrapper {
        .sidebar {
            @include media-breakpoint-down(xl) {
                display: none;
            }
        }
        .maincontent {
            margin-left: 0;

            @include media-breakpoint-up(md) {
                .container-fluid {
                    max-width: calc(map-get($container-max-widths, 'xxl') - $sideBarWidth);
                    @include media-breakpoint-down(xxl) {
                        max-width: calc(map-get($container-max-widths, 'xl') - $sideBarWidth-xl);
                    }
                    @include media-breakpoint-down(xl) {
                        max-width: calc(map-get($container-max-widths, 'lg'));
                    }
                    @include media-breakpoint-down(lg) {
                        max-width: calc(map-get($container-max-widths, 'md'));
                    }
                    @include media-breakpoint-down(md) {
                        max-width: calc(map-get($container-max-widths, 'sm'));
                    }
                }
            }
            .main-content-2-col > .container-fluid {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.layout-2,
.layout-3 {
    .container-wrapper.maincontent-wrapper {
        margin-top: 0;
        .maincontent {
            margin-top: 11.125rem;
            @include media-breakpoint-down(xl) {
                margin-top: 7.125rem;
            }
            margin-left: 0;
            //margin-top: 0;
            .container-fluid {
                max-width: calc(map-get($container-max-widths, 'xxl'));
                @include media-breakpoint-down(xxl) {
                    max-width: calc(map-get($container-max-widths, 'xl'));
                }
                @include media-breakpoint-down(xl) {
                    max-width: calc(map-get($container-max-widths, 'lg'));
                }
                @include media-breakpoint-down(lg) {
                    max-width: calc(map-get($container-max-widths, 'md'));
                }
                @include media-breakpoint-down(md) {
                    max-width: calc(map-get($container-max-widths, 'sm'));
                }
                &.container-fluid-wide .container-fluid {
                    max-width: calc(map-get($container-max-widths, 'xxl') - $sideBarWidth - $sideBarMargin);
                    @include media-breakpoint-down(xxl) {
                        max-width: calc(map-get($container-max-widths, 'xl') - $sideBarWidth-xl - $sideBarMargin-xl);
                    }
                    @include media-breakpoint-down(xl) {
                        max-width: calc(map-get($container-max-widths, 'lg'));
                    }
                    @include media-breakpoint-down(lg) {
                        max-width: calc(map-get($container-max-widths, 'md'));
                    }
                    @include media-breakpoint-down(md) {
                        max-width: calc(map-get($container-max-widths, 'sm'));
                    }
                    &.container-fluid-wide {
                        @include media-breakpoint-down(xl) {
                            max-width: calc(map-get($container-max-widths, 'lg')) !important;;
                        }
                        @include media-breakpoint-down(lg) {
                            max-width: calc(map-get($container-max-widths, 'md')) !important;
                        }
                        @include media-breakpoint-down(md) {
                            max-width: calc(map-get($container-max-widths, 'sm')) !important;
                        }
                    }
                }
            }
        }
    }
}

.container-wrapper-wide {
    margin-top: 7.4rem;
    @include media-breakpoint-down(xl) {
        margin-top: 4.3rem;
    }
    .page-content-main > .container-fluid {
        width: 100%;
        max-width: 100%;
    }
}

.layout-3 {
    .container-fluid-wide-x {
        position: absolute;
        top: 7.4rem;
        left: 0;
        width: 100% !important;
        max-width: 100% !important;
        color: white;
        z-index: 1;
        & + div {
            margin-top: 4.25rem;
        }
        @include media-breakpoint-down(xl) {
            top: 4.3rem;
        }
    }
}

.page-header {
    .container-wrapper {
        .sidebar {
            @include media-breakpoint-down(sm) {
                width: 230px;
                max-width: 230px;
            }
        }
        .maincontent {
            margin-left: 0;
            .container-fluid {
                max-width: calc(map-get($container-max-widths, 'xxl') - $sideBarWidth);
                @include media-breakpoint-down(xxl) {
                    max-width: calc(map-get($container-max-widths, 'xl') - $sideBarWidth-xl);
                }
                @include media-breakpoint-down(xl) {
                    max-width: calc(map-get($container-max-widths, 'lg') - $sideBarWidth-lg);
                }
                @include media-breakpoint-down(lg) {
                    max-width: calc(map-get($container-max-widths, 'md') - $sideBarWidth-md);
                }
                @include media-breakpoint-down(md) {
                    //max-width: calc(map-get($container-max-widths, 'sm') - $sideBarWidth-sm);
                    max-width: none;
                }
            }
            &.no-sidebar {
                //margin-left: $sideBarWidth;
                .container-fluid {
                    max-width: calc(map-get($container-max-widths, 'xxl'));
                    @include media-breakpoint-down(xxl) {
                        max-width: calc(map-get($container-max-widths, 'xl'));
                    }
                    @include media-breakpoint-down(xl) {
                        max-width: calc(map-get($container-max-widths, 'lg'));
                    }
                    @include media-breakpoint-down(lg) {
                        max-width: calc(map-get($container-max-widths, 'md'));
                    }
                    @include media-breakpoint-down(md) {
                        //max-width: calc(map-get($container-max-widths, 'sm'));
                        max-width: none;
                    }
                }
            }
        }
    }
}

.page-footer {
    .container-wrapper {
        .maincontent {
            margin-top: 0 !important;
            margin-left: 0 !important;
        }
    }
}
