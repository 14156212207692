// Generate different font sizes
//
// eg: .font-size-larger-lg-10
@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @each $size, $length in $fontsizes {
            @if $size != 0 {
                .font-size-larger#{$infix}-#{$size} { font-size: $length !important; }
            }
        }
    }
}

// eg: .font-size-smaller-lg-2
@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @each $size, $length in $fontsizes-small {
            @if $size != 0 {
                .font-size-smaller#{$infix}-#{$size} { font-size: $length !important; }
            }
        }
    }
}

//// eg: .font-size-sm-8 -> 0.60rem
//@each $size, $length in $fontsizes-small {
//    @if $size != 0 {
//        .font-size-small-#{$size} { font-size: $length !important; }
//    }
//}

// Generate different line-heights
//
// eg: .line-height-large-10
@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @each $size, $length in $fontsizes {
            @if $size != 0 {
                .line-height-larger#{$infix}-#{$size} { line-height: $length !important; }
            }
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @each $size, $length in $fontsizes-small {
            @if $size != 0 {
                .line-height-smaller#{$infix}-#{$size} { line-height: $length !important; }
            }
        }
    }
}

.line-height-none {
    line-height: 0;
}
