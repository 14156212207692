.tx-ids-iptocountry {
    position: absolute;
    width: 100%;
    height: 100%;
    .iptocountry-container {
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 250px;
        @media(max-width: 768px) {
            padding-top: 0;
        }
        .iptocountry-container-logo {
            position: absolute;
            top: 0;
            right: 20px;
            width: 400px;
            max-width: 75%;
            @media(max-width: 768px) {
                position: relative;
                right: inherit;
                margin: 0 auto 60px auto;
            }
        }
        h1 {
            margin-bottom: 20px;
        }
        .subheader {
            margin-bottom: 30px;
        }
        .iptocountry-actual-location,
        .iptocountry-select-location {
            margin-bottom: 20px;
            text-transform: uppercase;
            a,
            .location-info {
                text-align: center;
                display: block;
                padding: 8px 20px;
                border: 1px solid #a9a8a8;
                background: #fff;
                font-weight: bold;
            }
        }
        .iptocountry-select-location {
            cursor: pointer;
            .location-items {
                display: none;
                a {
                    border: 0;
                }
            }
        }
    }
}

.iptocountry-modal {
    .modal-footer {
        button, a {
            float: left;
            @media(max-width: 768px) {
                width: 100%;
                margin-bottom: 4px !important;
                margin-left: 0 !important;
            }
        }
    }
}