.contentposition-4 {
    position: sticky;
    top: 9rem;
}

.breadcrumb-section {
	padding: 50px 0;
	ol.breadcrumb {
		background: none;
		padding: 0;
		margin: 0;
	}
}

.page-title {
	margin-bottom: 80px;
	div.subheader {
		font-size: $font-size-lg;
	}
}
.backendlayout-pagets__default {
	.page-title {
		margin-top: 100px;
	}
}


.page-content {
	.container-fluid,
	.article {
        clear: both;
		&.margin-bottom-extra-small {
			margin-bottom: 30px !important;
		}
		&.margin-bottom-small {
			margin-bottom: 60px !important;
		}
		&.margin-bottom-medium {
			margin-bottom: 80px !important;
		}
		&.margin-bottom-large {
			margin-bottom: 110px !important;
		}
		&.margin-bottom-extra-large {
			margin-bottom: 140px !important;
		}
		&.margin-top-extra-small {
			margin-top: 30px !important;
		}
		&.margin-top-small {
			margin-top: 60px !important;
		}
		&.margin-top-medium {
			margin-top: 80px !important;
		}
		&.margin-top-large {
			margin-top: 110px !important;
		}
		&.margin-top-extra-large {
			margin-top: 140px !important;
		}
	}

    @include media-breakpoint-down(lg) {
        .container-fluid.elementheight-2,
        .article.elementheight-2 {
            &.margin-bottom-extra-small,
            &.margin-bottom-small,
            &.margin-bottom-medium,
            &.margin-bottom-large,
            &.margin-bottom-extra-large {
                margin-bottom: 0 !important;
            }
        }
    }
	&.page-margin-top {
		margin-top: 84px;
	}
	.bg-gray-300 {
        &:not(.ce-layout-14):not(.ce-layout-15) {
            background-color: $elementbackground-gray;
        }
	}
    .bg-padding {
        @extend .py-md-10;
        @extend .px-md-16;
        @extend .py-5;
        @extend .px-4;

    }
	//.ce-layout-1,
	//.ce-layout-8 {
	//	padding-top: $element-padding-2x;
	//	padding-bottom: $element-padding-2x;
	//}
	//.ce-layout-3 {
	//	.row {
	//		margin-left: -($grid-gutter-width);
	//		margin-right: -($grid-gutter-width);
	//	}
	//	.ce-menu {
	//		//padding: 0 !important;
	//		.item {
	//			padding-bottom: $grid-gutter-width;
	//			padding: 0 ($grid-gutter-width/2);
	//			//$media(max-width: $screen-md-max) {
    //            @include media-breakpoint-down(lg) {
	//				padding: 0 10px;
	//			}
	//			.item-wrapper {
	//				border: 1px solid $gray-light;
	//				line-height: 1.2;
	//				a {
	//					&:focus { text-decoration: none; }
	//				}
	//				.item-image {
	//					background-color: $gray-lighter;
	//					padding: 20px 0;
	//					&.image-center {
	//						text-align: center;
	//						img {
	//							margin: 0 auto;
	//						}
	//					}
	//				}
	//				.item-text {
	//					padding: 20px;
	//					font-weight: 700;
	//					//$media(max-width: $screen-md-max) {
    //                    @include media-breakpoint-down(lg) {
	//						padding: 10px;
	//					}
	//				}
	//				.item-footer {
	//					border-top: 4px double $gray-lighter;
	//					padding: 10px 0;
	//					margin: 0 20px;
	//					//$media(max-width: $screen-md-max) {
    //                    @include media-breakpoint-down(lg) {
	//						margin: 0 10px;
	//					}
	//				}
	//			}
	//		}
	//	}
	//}
	//.ce-layout-4 {
	//	> .ce-textpic {
	//		> header {
	//			margin-bottom: 80px;
	//		}
	//		> .ce-bodytext {
	//			h3 {
	//				color: $primary;
	//			}
	//		}
	//	}
	//	.article {
	//		margin-bottom: 80px;
	//	}
	//}

	// image behind text
	.ce-img-behind {
		position: relative;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
        height: 0;
        padding-bottom: 86%;

		.ce-bodytext {
			//position: absolute;
			//min-height: 350px;

			//top: 0;
			//left: 0;
			width: 100%;
			//height: 100%;
			//background-color: rgba(225,225,225,0.7);
			padding-top: 50px;
			padding-left: 25px;
			padding-right: 25px;

			p {
				margin-bottom: 20px;
				padding: 0;

			}
			h1,h2,h3,h4,h5 {
				margin-bottom: 20px;
				padding: 0;
				font-size: $font-size-large-1;
			}
		}
	}
    .ce-img-beside {
        p {
            margin-bottom: 0;
        }
    }
	//.ce-layout-2 {
	//	.ce-bodytext-wrapper {
	//		.ce-img-behind {
	//			height: 0;
	//			padding-bottom: 84%;
	//			overflow: hidden;
	//			.ce-bodytext {
	//				min-height: inherit;
	//				height: inherit;
	//			}
	//		}
	//	}
	//}
	> .page-content-main > .article,
	> .page-content-main > .container-fluid > .article {
		margin-bottom: 80px;
		&.ce-elementheight-2 {
			margin-bottom: 0;
		}
	}
	> .page-content-main > div:last-of-type {
		margin-bottom: 0;
	}
	.article {

		.ce-textpic {
			.ce-gallery {
				.ce-media {
					img {
						border-radius: $border-radius-sm;
					}
				}
			}
		}
		&.gridelement {
			.ce-bodytext {
				&.gridelement-two {
				    .ce-bodytext-wrapper {
                        &.d-flex {
                            flex-direction: column;
                            @include media-breakpoint-down(md) {
                                flex-direction: column;
                            }
                            //@include media-breakpoint-down(sm) {
                            //    flex-direction: column;
                            //}
                        }
                        .textmedia-element-layout-4 {
                            @include media-breakpoint-down(md) {
                                width: 49%;
                            }
                            @include media-breakpoint-down(sm) {
                                width: 100%;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
			}
            //@include media-breakpoint-down(md) {
			//	&:not(.ce-layout-2) {
			//		.ce-bodytext {
			//			.row {
			//				margin: 0;
			//			}
			//			&.gridelement-two {
			//				div[class*="col-"] {
			//					padding: 0 ($grid-gutter-width/2) !important;
			//				}
			//			}
			//		}
			//	}
			//}
		}
	}

	//.ce-layout-8 {
	//	.container {
	//		width: 50%;
	//		//max-width: $screen-md;
    //        max-width: map-get($grid-breakpoints, 'md');
	//		//$media(max-width: $screen-sm-max) {
    //        @include media-breakpoint-down(md) {
	//			width: 100%;
	//		}
	//	}
	//}

    //div[class*='margin-bottom-'] {
    //
    //}


    .element-centered-30 {
        width: 30%;
        margin: 0 auto;
        // padding-right: $sideBarMargin;
    }
    .element-centered-50 {
        width: 50%;
        margin: 0 auto;
        // padding-right: $sideBarMargin;
    }
    .element-centered-62 {
        width: 62%;
        margin: 0 auto;
        // padding-right: $sideBarMargin;
    }
    .element-centered-75 {
        width: 75%;
        margin: 0 auto;
        // padding-right: $sideBarMargin;
    }
    .element-centered-80 {
        width: 80%;
        margin: 0 auto;
        // padding-right: $sideBarMargin;
    }
    .element-centered-100 {
        width: 100%;
        margin: 0 auto;
        // padding-right: $sideBarMargin;
    }

    @include media-breakpoint-down(xl) {
        .element-centered-30,
        .element-centered-50,
        .element-centered-62,
        .element-centered-75,
        .element-centered-80,
        .element-centered-100 {
            padding-right: 0;
        }
    }

    @include media-breakpoint-down(lg) {
        .element-centered-30 {
            width: 40%;
            margin: 0 auto;
        }
        .element-centered-50 {
            width: 60%;
            margin: 0 auto;
        }
        .element-centered-62 {
            width: 72%;
            margin: 0 auto;
        }
        .element-centered-75 {
            width: 85%;
            margin: 0 auto;
        }
        .element-centered-80 {
            width: 90%;
            margin: 0 auto;
        }
    }


    @include media-breakpoint-down(md) {
        .element-centered-30,
        .element-centered-50,
        .element-centered-62,
        .element-centered-75,
        .element-centered-80 {
            width: 100%;
        }
    }
    @include media-breakpoint-down(md) {
        .elementlayout-2 {
            padding-left: 0;
            padding-right: 0;
            .element-centered-30,
            .element-centered-50,
            .element-centered-62,
            .element-centered-75,
            .element-centered-80,
            .element-centered-80 {
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .page-content-centered {
        width: 60%;
        margin: 0 auto;

        //@include media-breakpoint-down(xl) {
        //    width: 80%;
        //}
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        .ce-textpic {
            position: relative;
            max-width: 1165px;
        }
        .bg-gray-300.ce-layout-14,
        .bg-gray-300.ce-layout-15 {
            .ce-bodytext {
                background-color: $elementbackground-gray;
            }
        }

        //div.article {
        .bg-gray-300.ce-layout-14,
        .bg-gray-300.ce-layout-15 {
            background: none !important;
            .ce-bodytext {
                &:not(.ce-bodytext-no-image) {
                    position: relative;
                    width: 72%;
                    left: 12%;
                    padding: 31% 4rem 5rem 4rem;
                    margin-top: 12rem;
                    @include media-breakpoint-down(xxl) {
                        padding-top: 24%;
                    }
                    @include media-breakpoint-down(xl) {
                        padding-top: 38%;
                    }
                    @include media-breakpoint-down(md) {
                        padding-top: 46%;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        width: 94%;
                        left: 3%;
                    }
                }

                &.ce-bodytext-no-image {
                    width: 75%;
                    margin: 0 auto;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }

            .ce-layout-15 {
                .ce-bodytext:not(.ce-bodytext-no-image) {
                    margin-top: 0;
                }
            }

            .ce-gallery:not(.ce-gallery-no-content) {
                position: absolute;
                top: 0;
                @include media-breakpoint-down(xl) {
                    top: -70px;
                }
            }
            .ce-gallery.ce-gallery-no-content {
                width: 75%;
                margin: 0 auto;
            }
            &.gridelement {
                .ce-bodytext {
                    &:not(.ce-bodytext-no-image) {
                        padding: 0 0 0 0;
                        margin-top: 0;
                    }
                    &.ce-bodytext-no-image {
                        width: 100%;
                        margin: 0 auto;
                    }
                    .ce-bodytext-wrapper {
                        @extend .mb-12;
                        @extend .mb-md-0;
                        //@include media-breakpoint-down(md) {
                        //    &:first-of-type {
                        //        margin-bottom: 10rem;
                        //    }
                        //}
                    }
                }
            }
        }
    }

    .page-content-main {
        .bg-gray-300.ce-layout-14,
        .bg-gray-300.ce-layout-15 {
            padding: 0 !important;
            background: none !important;
            .ce-textpic {
                position: relative;
                padding-right: $sideBarMargin;
                @include media-breakpoint-down(lg) {
                    padding-right: 0;
                }
            }
            .ce-bodytext {
                background-color: $elementbackground-gray;
                &:not(.ce-bodytext-no-image) {
                    position: relative;
                    //width: 72.5%;
                    //left: 9.6%;
                    width: 75%;
                    padding: 31% 4rem 5rem 4rem;
                    margin: 12rem auto 0 auto;
                    h2 {
                        position: relative;
                        z-index: 9;
                    }
                    @include media-breakpoint-down(xxl) {
                        //width: 76%;
                        //left: 8.5%;
                        padding-top: 24%;
                    }
                    //@include media-breakpoint-down(xl) {
                    //    width: 85%;
                    //    left: 7.4%;
                    //    padding-top: 38%;
                    //}
                    @include media-breakpoint-down(lg) {
                        //width: 82%;
                        //left: 8%;
                        padding-top: 38%;
                    }
                    @include media-breakpoint-down(md) {
                        padding-top: 46%;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        width: 90%;
                        //left: 4%;
                    }
                }

                &.ce-bodytext-no-image {
                    width: 75%;
                    margin: 0 auto;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }

            .ce-layout-15 {
                .ce-bodytext:not(.ce-bodytext-no-image) {
                    margin-top: 0;
                }
            }

            .ce-gallery:not(.ce-gallery-no-content) {
                position: absolute;
                top: 0;
                @include media-breakpoint-down(xl) {
                    top: -70px;
                }
            }
            .ce-gallery.ce-gallery-no-content {
                width: 75%;
                margin: 0 auto;
            }
            &.gridelement {
                .ce-bodytext {
                    &:not(.ce-bodytext-no-image) {
                        padding: 0 0 0 0;
                        margin-top: 0;
                    }
                    &.ce-bodytext-no-image {
                        width: 100%;
                        margin: 0 auto;
                    }
                    .ce-bodytext-wrapper {
                        @extend .mb-12;
                        @extend .mb-md-0;
                        //@include media-breakpoint-down(md) {
                        //    &:first-of-type {
                        //        margin-bottom: 10rem;
                        //    }
                        //}
                    }
                }
            }
        }
    }

    .page-content-centered-larger {
        width: 80%;
        margin: 0 auto;
        @include media-breakpoint-down(xl) {
            width: 100%;
        }
        div.article {
            &.gridelement {
                .ce-bodytext {
                    &:not(.ce-bodytext-no-image) {
                        padding: 0 0 0 0;
                        margin-top: 0;
                    }
                    &.ce-bodytext-no-image {
                        width: 100%;
                        margin: 0 auto;
                    }
                    .ce-bodytext-wrapper {
                        @extend .mb-12;
                        @extend .mb-md-0;
                    }
                }
            }
        }
    }

	//
	// thumbnail list
	// ---------------------------------------------
	ul.ce-menu-thumnail-list {
		list-type: none;
		margin: 0;
		padding: 0;
		li {
			list-style: none;
			margin: 0;
			padding: 0;
			float: left;
		}

	}
	div.ce-menu-thumbnail-list {
		.item-wrapper {
			a {
				.item-text {
					color: $text-color;
				}
			}
		}
	}
	//
	// menu list
	// ---------------------------------------------
	ul.ce-menu-0 {

		list-type: none;
		margin: 20px 0 0 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		li {
			list-style: none;
			margin: 0 10px;
			padding: 0;
			background-color: $primary;
			line-height: 1.2;
			//display: table;
			//flex-basis: 50%;
			width: 300px;
			height: 110px;
			margin-bottom: ($grid-gutter-width/2);
			//$media(max-width: $screen-sm-max) {
            @include media-breakpoint-down(md) {
				-webkit-flex-grow: 1;
				flex-grow: 1;
				-webkit-flex-basis: auto;
				flex-basis: auto;
			}
			a {
				display: block;
				padding: 15px;
				overflow: hidden;
				color: #fff;
				//max-width: 300px;
				text-transform: uppercase;
				font-size: $font-size-base;
				text-align: center;
				position: relative;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}

	}
	//
	// uploads file links
	// ---------------------------------------------
	ul.ce-uploads {
		list-type: none;
		margin: 20px 0 0 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		li {
			list-style: none;
			margin: 0 10px;
			padding: 0;
			background-color: $gray;
			line-height: 1.2;
			//display: table;
			//flex-basis: 50%;
			width: 300px;
			height: 110px;
			margin-bottom: ($grid-gutter-width/2);
			//$media(max-width: $screen-sm-max) {
            @include media-breakpoint-down(md) {
				-webkit-flex-grow: 1;
				flex-grow: 1;
				-webkit-flex-basis: auto;
				flex-basis: auto;
			}
			a {
				display: block;
				padding: 15px;
				overflow: hidden;
				color: #fff;
				//max-width: 300px;
				text-transform: uppercase;
				font-size: $font-size-base;
				text-align: center;
				position: relative;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}

	}

	//
	// Responsive image settings
	// --------------------------------------------------
	.ce-intext {
		.ce-gallery {
			width: 50%;
			margin-right: 0;
			margin-left: 0;
		}
		&.ce-left .ce-gallery {
			padding-right: 80px;
		}
		&.ce-right .ce-gallery {
			margin-left: 80px;
		}
	}
	//.ce-layout-4 .ce-intext {
	//	.ce-gallery {
	//		width: 15%;
	//	}
	//	&.ce-left .ce-gallery {
	//		padding-right: 20px;
	//	}
	//	&.ce-right .ce-gallery {
	//		margin-left: 20px;
	//	}
	//}

    .ce-above {
        &.ce-right {
            float: right;
        }
    }


	.ce-gallery {
		.ce-column {
			margin: 0;
			padding: 0 10px;
			box-sizing: border-box;
		}
	}

	.ce-gallery .ce-column:first-child {
		//padding-left: 0;
	}
	.ce-gallery .ce-column:last-child {
		//margin-right: 0;
	}

	.ce-gallery[data-ce-columns="1"] .ce-column {
			padding: 0;
	}

	.ce-gallery[data-ce-columns="2"] .ce-column {
			width: 50%;
	}

	.ce-gallery[data-ce-columns="3"] .ce-column {
			width: 33.33%;
	}

	.ce-gallery[data-ce-columns="4"] .ce-column {
			width: 25%;
	}

	.ce-gallery[data-ce-columns="5"] .ce-column {
			width: 20%;
	}
	.ce-gallery[data-ce-columns="6"] .ce-column {
			width: 16.666667%;
	}

	@include media-breakpoint-down(lg) {
        .ce-gallery[data-ce-columns="2"] .ce-column {
            width: 50% !important;
            padding-bottom: 0.25rem;
            &:first-of-type {
                padding-right: 0.125rem;
            }
            &:last-of-type {
                padding-left: 0.125rem;
            }
        }
    }

    @include media-breakpoint-down(md) {
		.ce-intext .ce-gallery {
			width: 100%;
		}
		.ce-gallery .ce-column {
			padding: 0;
		}
		.ce-gallery .ce-column {
			width: 100% !important;
			//margin-bottom: 10px !important;
			.img-fluid {
				width: 100%;
			}
		}
	}

	ul.nav-tabs {

		li {
            display: flex;
            align-items: center;
			&:not(:first-of-type) {
				margin-left: -1px;
			}
            &:hover {
                background-color: fade-in($primary, 0.6);
                //background-color: scale-color($primary, 60%);
            }
			a {
                display: block;
				border-radius: 0;
				padding: 4px 30px;
				margin: 0;
				&:hover {
                    //background-color: fade-in($primary, 0.6);
					//background-color: scale-color($primary, 60%);
					color: #fff;
				}
                //&.active {
                //    background-color: $primary;
                //    color: #fff;
                //}
			}
			&.active {
                background-color: $primary;
				a {
                    //background-color: $primary;
					color: #fff;
				}
			}
		}
	}
	.tab-content {
		padding-top: 25px;
		padding-bottom: 80px;
		.tab-pane {
			.article {
			}
		}
	}


	// tab content
	.tab-v1 {
		h1,h2,h3,h4,h5 {
			margin-bottom: 80px;
		}
		ul.nav-tabs {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			li {
				flex-basis: 0;
				flex-grow: 1;
				font-weight: bold;
				//$media (max-width: $screen-sm-max) {
                @include media-breakpoint-down(md) {
					flex-basis: 50%;
				}
				//$media (max-width: $screen-xs-max) {
                @include media-breakpoint-down(sm) {
					flex-basis: 100%;
				}
				a {
					line-height: 1.3;
					padding: 12px 24px;

				}
			}
		}
	}

    .well {
        min-height: 20px;
        padding: 19px;
        margin-bottom: 20px;
        background-color: $gray-100;

        blockquote {
            border-color: #ddd;
            border-color: rgba(0,0,0,.15);
        }
    }
}

.layout-3 {
    .element-centered-30 {
        padding-right: 0;
    }
    .element-centered-50 {
        padding-right: 0;
    }
    .element-centered-62 {
        padding-right: 0;
    }
    .element-centered-75 {
        padding-right: 0;
    }
    .element-centered-80 {
        padding-right: 0;
    }
    .element-centered-100 {
        padding-right: 0;
    }
}

.pagets__special_start {
	.page-content {
		//.article.gridelement.ce-layout-2 {
		//	border-bottom: 6px solid #fff;
		//	margin-bottom: 0;
		//	.ce-bodytext {
		//		a {
		//			color: inherit;
		//		}
		//		.row {
		//			margin: 0;
		//		}
		//		div[class*="col-"] {
		//			padding: 0;
		//			border-right: 6px solid #fff;
        //
		//			a {
		//				display: block;
		//				width: 100%;
		//				height: 100%;
		//				span.info-bg {
		//					background-color: $primary;
		//					padding: 4px 10px;
		//					color: #fff;
		//					text-transform: uppercase;
		//					font-size: $font-size-large-1;
        //
		//					display: inline;
		//					position: absolute;
		//					top: 24px;
		//					left: 24px;
		//					z-index: 1;
		//				}
		//				.ce-textpic-wrapper-bg {
		//					position: absolute;
		//					width: 100%;
		//					height: 100%;
		//					top: 0;
		//					left: 0;
		//					background-color: rgba(42,43,47,0);
		//					z-index: 0;
		//					@include transition-theme-2(background-color, $teaser-main-duration-time, ease, $teaser-main-transition-time);
		//				}
		//				div.ce-textpic-wrapper {
		//					position: absolute;
		//					padding: 0 25px;
		//					left: 0;
		//					bottom: 0;
		//					height: 100px;
		//					width: 100%;
		//					z-index: 1;
		//					text-transform: none;
		//					p {
		//						-webkit-transform: translate3d(0, 0, 0);
		//						transform: translate3d(0, 0, 0);
		//						color: #fff;
		//						line-height: 1.2em;
		//						font-size: $font-size-lg;
        //
		//						@include transition-theme-2(all, $teaser-main-duration-time, ease, $teaser-main-transition-time);
        //
		//						&.teaser-title {
		//							font-size: $font-size-large-1;
		//							font-weight: 700;
		//							text-transform: uppercase;
		//							margin-bottom: 0;
        //
		//							a {
		//								color: #fff;
		//							}
		//						}
        //
		//						&.teaser-divider {
		//							display: block;
		//							width: 50px;
		//							margin-bottom: 15px;
		//							padding-bottom: 15px;
		//							border-bottom: 2px solid $primary;
		//							height: 1px;
		//						}
		//					}
		//					div.teaser-text {
		//						-webkit-transform: translate3d(0, 0, 0);
		//						transform: translate3d(0, 0, 0);
        //
		//						@include transition-theme-2(all, $teaser-main-duration-time, ease, $teaser-main-transition-time);
		//						p {
		//							opacity: 0;
		//							@include transition-theme-2(all, $teaseropacity-main-duration-time, ease, $teaseropacity-main-transition-time);
		//						}
		//					}
		//				}
		//				&:hover {
		//					div.ce-textpic-wrapper-bg {
		//						background-color: rgba(42,43,47,0.75);
		//						@include transition-theme-2(background-color, $teaser-main-duration-time, ease, $teaser-main-transition-time);
		//					}
		//					div.ce-textpic-wrapper {
		//						p {
		//							-webkit-transform: translate3d(0,-140px,0);
		//							transform: translate3d(0,-140px,0);
		//							@include transition-theme-2(all, $teaser-main-duration-time, ease, $teaser-main-transition-time);
		//							//$media (max-width: $screen-lg) {
        //                            @include media-breakpoint-down(xl) {
		//								-webkit-transform: translate3d(0,-100px,0);
		//								transform: translate3d(0,-100px,0);
		//							}
		//							//$media (max-width: $screen-md) {
        //                            @include media-breakpoint-down(lg) {
		//								-webkit-transform: translate3d(0,-200px,0);
		//								transform: translate3d(0,-200px,0);
		//							}
		//							//$media (max-width: $screen-sm) {
        //                            @include media-breakpoint-down(md) {
		//								-webkit-transform: translate3d(0,-150px,0);
		//								transform: translate3d(0,-150px,0);
		//							}
		//							//$media (max-width: $screen-xs) {
        //                            @include media-breakpoint-down(sm) {
		//								-webkit-transform: translate3d(0,-80px,0);
		//								transform: translate3d(0,-80px,0);
		//							}
		//							&.teaser-divider {
		//								opacity: 1;
		//								@include transition-theme-2(all, $teaseropacity-main-duration-time, ease, $teaseropacity-main-transition-time);
		//							}
		//						}
		//						div.teaser-text {
		//							-webkit-transform: translate3d(0,0,0);
		//							transform: translate3d(0,0,0);
		//							p {
		//								opacity: 1;
		//								@include transition-theme-2(all, $teaseropacity-main-duration-time, ease, $teaseropacity-main-transition-time);
		//							}
		//						}
		//					}
		//				}
		//			}
		//		}
		//		&.gridelement-two {
		//			div[class*="col-"] {
		//				&:last-of-type {
		//					border: 0;
		//				}
		//			}
		//		}
		//		&.gridelement-three {
		//			div[class*="col-"] {
		//				&:last-of-type {
		//					border-right: 0;
		//					border-bottom: 6px solid #fff;
		//					padding-right: 6px;
		//					.ce-img-behind {
		//						margin-right: -6px;
		//					}
		//				}
		//			}
		//		}
		//	}
		//	//$media(max-width: $screen-sm-max) {
        //    @include media-breakpoint-down(md) {
		//		.ce-bodytext-wrapper {
		//			border-bottom: 6px solid #fff;
		//			&:last-of-type {
		//				border-bottom: 0;
		//			}
		//		}
		//	}
		//}
	}
}

.backendlayout-pagets__default_clean {
	.page-content {
		&.page-margin-top {
			margin-top: 62px;
			@media(max-width: 1600px) {
				margin-top: 83px;
			}
			@media(max-width: 1300px) {
				margin-top: 80px;
			}
			//@media(max-width: $screen-sm-max) {
            @include media-breakpoint-down(md) {
				margin-top: 39px;
			}
		}
	}
}

.backendlayout-pagets__products_pim_overview,
.backendlayout-pagets__products_pim_landingpage {
    .page-content {
        margin-top: 184px;
    }
}
