.form-group {
    margin-bottom: 25px;
}

.parsley-errors-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        color: $primary;
        font-size: 0.75rem;
    }
}

.jobs-filter {
    .form-select {
        padding: 0.75rem 2.25rem;
        background-color: darken($primary, 10);
        color: $white;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        border-color: darken($primary, 10);
        box-shadow: none;
        &:focus {
            box-shadow: none;
            border-color: darken($primary, 10);
        }
        option[disabled="disabled"] {
            color: $gray-400;
        }
    }
}
