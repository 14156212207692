.tx-ids-product-pim {
    h1 {
        font-size: $font-size-base * 2.125;
        margin-bottom: 0;
    }

    .series-info-text {
        margin-left: -0.9rem;
        margin-right: -0.9rem;
        padding: 0;
    }
    ul {
        &.nav:not(.nav-pills) {
            a {
                &.active,
                &:hover {
                    color: $primary !important;
                    &:after {

                        font-family: 'Font Awesome 6 Free';
                        font-weight: 900;
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        line-height: 1;
                        content: "\f054";
                        @extend .me-2;
                    }
                }
            }
        }
        &.nav-pills {
            .nav-link:not(.active) {
                color: $body-color;
                background-color: $gray-100;
                &:hover {
                    background-color: $gray-800;
                    color: $white;
                }
            }
            flex-wrap: initial;
            flex-direction: row;
            white-space: nowrap;
            overflow-x: scroll;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: thin;  /* Firefox */
            scrollbar-color: $gray-200 $white;
            &::-webkit-scrollbar {
                width: 4px;  /* Remove scrollbar space */
                height: 2px;
                background: none;  /* Optional: just make scrollbar invisible */
            }
            //&::-webkit-scrollbar-track {
            //    background: #f39;
            //}
            /* Optional: show position indicator in red */
            &::-webkit-scrollbar-thumb {
                background: $gray-200;
                border-radius: 6px;
                //border: 5px solid $white;
            }


        }
    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    // content settings
    .ce-layout-1 {
        padding-top: 0;
    }
    .container-fluid {
        display: flex;
        //:not(.slick-slider) {
            .article {
                &.ce-layout-0 {
                    width: 85%;
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }

                    &.gridelement {
                        .ce-bodytext-wrapper {
                            display: flex;
                            align-items: center !important;
                            //justify-content: flex-end !important;
                        }

                        .article {
                            width: 100%;

                            .ce-gallery {
                                margin: 0;
                            }
                        }
                    }
                }
                &.ce-layout-12 {
                    &.gridelement {
                        .ce-bodytext-wrapper {
                            display: flex;
                            align-items: center !important;
                            //justify-content: flex-end !important;
                        }

                        .article {
                            width: 100%;

                            .ce-gallery {
                                margin: 0;
                            }
                        }
                    }
                }
                &.ce-layout-10 {
                    //@extend .ce-layout-0;
                    border-top: 1px solid $gray-500;
                    border-bottom: 1px solid $gray-500;
                    padding-top: 4.375rem;
                    padding-bottom: 4.375rem;

                    p {
                        margin: 0;
                    }
                }
                &.ce-layout-11 {
                    width: 85%;
                    .ce-textpic {
                        width: 45%;
                        margin: 0 auto;
                        @include media-breakpoint-down(sm) {
                            width: 80%;
                        }
                    }
                }
                &.ce-layout-13 {
                    width: 80%;
                    margin: 0 auto;
                    .article.layout-0 {
                        width: 100%;
                    }
                    &.gridelement {
                        .ce-bodytext-wrapper {
                            display: flex;
                            align-items: center !important;
                            justify-content: flex-end !important;
                        }

                        .article {
                            width: 100%;

                            .ce-gallery {
                                margin: 0;
                            }
                        }
                    }
                }

            }
            .ce-bodytext-wrapper.col {
                @include media-breakpoint-down(sm) {
                    flex: 1 0 100%;
                    //justify-content: center;
                }
            }
        //}
    }
    .features-and-options-item,
    .gridelement {
        .container-fluid {
            padding: 0;
            .article {
                &.ce-layout-0 {
                    width: 100%;
                }
            }
        }
    }
    .slick-slider {
        .article {
            &.ce-layout-0 {
                width: 100%;
            }
        }
        //.slick-slide {
        //
        //    .container {
        //        //padding: 0;
        //    }
        //}
    }
    //.features-and-options-item {
    //    .container {
    //
    //    }
    //}
    .accordion-container {
        //width: 80%;
        //margin: 0 auto;
        h2,
        .accordion {
            width: 80%;
            margin: 0 auto;
        }
        a.accordion-button {
            color: $body-color;
            line-height: 1.5rem;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    .pim-list-item {
        .container-fluid {
            padding: 0;
        }
        .article {
            padding-bottom: 0;
        }
        :not(.slick-slider) .container-fluid {
            padding: 0;
        }
        //.ce-textpic {
        //    display: flex;
        //    flex-direction: column;
        //    .ce-gallery {
        //        width: 100% !important;
        //        padding-right: 0 !important;
        //    }
        //    .ce-bodytext {
        //        header {
        //            h1,h2,h3,h4,h5 {
        //                font-size: $font-size-base;
        //            }
        //        }
        //    }
        //}
    }
    .pim-overview-content {
        .h-100 {
            height: auto !important;
        }
        .container-fluid {
            padding: 0;
        }
    }

    .pim-detail-properties {
        .row {
            @extend .g-0;
            @extend .border-bottom;
            @extend .border-gray-200;
            @extend .pb-5;
            @extend .mb-5;
        }
        i {
            width: 15px;
            @extend .ps-2;
            @extend .pe-10;
        }
    }
}

.product-pim-heatpumpoverview {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        .col-md-4 {
            width: 33.333% !important;
        }
    }
}

.article.gridelement .ce-bodytext.gridelement-two .ids-products-pim div[class*="col-"]:nth-child(1),
.article.gridelement .ce-bodytext.gridelement-two .ids-products-pim div[class*="col-"]:nth-child(2) {
    padding: 0 ($grid-gutter-width/2) !important;
}

.pim-overview-content {
    .carousel-wrapper {
        .scroll-item {
            margin-right: $grid-gutter-width;
            @include media-breakpoint-down(xl) {
                margin-right: $grid-gutter-width/2;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
        //.container-fluid {
        //    padding-left: 0;
        //    padding-right: $grid-gutter-width;
        //}
    }
}
