/* Less vars
------------------------------------------------------------------------------ */
$border-line-color: lighten(#000000, 90);

/* Div things
------------------------------------------------------------------------------ */
.preloader-small {
	position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
	background-image: url(../Images/Icons/preloader-small.gif);
	background-repeat: no-repeat;
	background-position: center;
	background-color: rgba(10,10,10,0.5);
}
.preloader {
	position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
	background-image: url(../Images/Icons/preloader.gif);
	background-repeat: no-repeat;
	background-position: center;
	background-color: rgba(255,255,255,0.8);
	background-size: contain;
}

.error-fillup {
    border-color: red !important;
}

input[type="checkbox"].error-fillup {
    outline: 1px solid red;
}


/* Form
------------------------------------------------------------------------------ */

.tx-conveyinginfo {
	.btn {
		width: 100%;
	}
	.well {
		border: 0;
		box-shadow: none;
		padding: 35px;
	}
	.steps {
		background-color: $gray;
		padding: 35px;
		color: $gray-light;
		ul,
		ul li {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		ul {
			display: inline-block;
			li {
				float: left;
				margin-right: 30px;
				span.stepNo {
					width: 30px;
					height: 30px;
					display: inline-block;
					background-color: $gray-light;
					color: $gray;
					border-radius: 100%;
					text-align: center;
					margin-right: 5px;
				}
				&.active {
					span.stepNo {
						background-color: #fff;
					}
					span.stepName {
						color: #fff;
					}
				}
				&.success {
					span.stepNo {
						background-color: $success;
					}
					span.stepName {
						color: #fff;
					}
				}
			}
		}
	}
  form {
		fieldset {
			font-size: $font-size-sm;
			h3.margin-bottom {
				margin-top: 40px;
				margin-bottom: 10px;
			}
			.form-submit {
				margin-top: 60px;
			}

		}
	}
    h3 {
        margin: 0;
        margin-bottom: 20px;
        padding: 0;
        font-size: 18px;
    }
    .group-line {
        border-top: 1px solid $border-line-color;
        margin-top: 30px;
        padding-top: 30px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        > div[class*="col-"] {
            padding: 0;
        }
    }
}
