//$import "mixins.less";

.row-eq-height {
  overflow: hidden;
	margin-bottom: 25px;
	.well {
		margin-bottom: -9999999px;
		padding-bottom: 9999999px;

	}
}

.row-eq-height-1 {
	display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
	.well {
		height: 100%;
	}
}

.not-visible {
	display: none;
}


//
// Vertical Alignment
// --------------------------------------------------
.valign {
    display: table;
    table-layout: fixed;
    width: 100%;
    > .vcontainer {
        display: table-cell;
        vertical-align: middle;
    }
}


//
// Jumbotron
// --------------------------------------------------
.jumbotron {
    a,
    a:hover,
    a:active,
    a:focus {
        color: inherit;
        text-decoration: underline;
    }
}


//
// Panel
// --------------------------------------------------
.panel-body {
    *:last-child {
        margin-bottom: 0;
    }
}

.panel-border {
	border-color: $gray-lighter;
	.panel-title {
    border-color: $gray-lighter;
	}
}


//
// Lazyload Images
// --------------------------------------------------
img.lazyload {
    opacity: 0.3;
    @include transition(opacity .3s ease-in 0s);
}


//
// Language Menu
// --------------------------------------------------



//
// Buttons from RTE
// --------------------------------------------------
//.btn {
//    @extend .btn;
//	//.btn();
//	//font-size: 19px;
//	//border-radius: 0;
//	//font-weight: 300;
//    //
//	//letter-spacing: 1px;
//	//padding-left: 15px;
//	//padding-right: 15px;
//
//}
//.btn-default {
//    @extend .btn;
//    @extend .btn-default;
//	//.btn();
//	//.btn-default();
//	//background: none;
//	//color: $text-color;
//	//padding-left: 15px;
//	//padding-right: 15px;
//	&:focus,
//	&:active,
//	&:hover {
//		//background-color: ;
//		//color: $text-color;
//	}
//}
//.btn-primary {
//    @extend .btn;
//    @extend .btn-primary;
//}
//.btn-success {
//    @extend .btn;
//    @extend .btn-success;
//}
//.btn-warning {
//    @extend .btn;
//    @extend .btn-warning;
//}
//.btn-danger {
//    @extend .btn;
//    @extend .btn-danger;
//}
//
//.btn-fullWidth {
//	width: 100%;
//}

span.info-bg {
	background-color: $primary;
	padding: 4px 10px;
	color: #fff;
	text-transform: uppercase;
	font-size: $font-size-large-1;
}

//
// Misc
// --------------------------------------------------

.img-fluid {
	width: 100%\0;
}

.margin-bottom {
  margin-bottom: 40px;
}


div.maps.embed-container div {
	pointer-events: none;
}

.panel-group {
    margin-bottom: 20px;
}
a:hover {
    text-decoration: none;
}
.caret {
    margin-left: 5px;
}
.caption {
    color: $gray-light;
}


a {
	//.transition-theme(color, $link-hover-transition-time);
	@include transition-theme-2(all, $nav-main-duration-time, ease, $nav-main-transition-time);
}

a:hover {
	text-decoration: none;
	//.transition-theme(color, $link-hover-transition-time);
	@include transition-theme-2(all, $nav-main-duration-time, ease, $nav-main-transition-time);
}
a:focus {
	text-decoration: none;
}
a.hover-secondary:hover {
    color: $body-color;
    .fa-stack-1x {
        color: $white !important;
    }
}

a.hover-underline:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
}

a.link-arrow:after {
	display: inline-block;
	font-family: 'Font Awesome 6 Free';
    font-weight: 900;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\f054";
	padding-left: 15px;
    //@extend .fa;
    //@extend .fa-chevron-right;
}

.align-left {
	text-align: left;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.align-justify {
	text-align: justify;
}

.text-large { font-size: $font-size-large; }
.text-large-1 { font-size: $font-size-large-1; }
.text-large-2 { font-size: $font-size-large-2; }
.text-large-3 { font-size: $font-size-large-3; }
.text-large-4 { font-size: $font-size-large-4; }
.text-large-5 { font-size: $font-size-large-5; }

.text-small {
	font-size: $font-size-sm;
}
.text-small-1 {
	font-size: $font-size-small-1;
}

figcaption.csc-textpic-caption {
	display: block;
}

:focus {
	outline: none !important;
}

.checkbox-inline,
.radio-inline {
	label {
		font-weight: normal;
	}
}

//.page-content .ce-textpic {
//	ul:not(.list-style-check),
//	ul:not(.list-style-check) li {
//		list-style: none;
//		margin: 0 !important;
//		padding: 0 0 0 22px !important;
//	}
//	ul:not(.list-style-check) li:before {
//		display: inline-block;
//        font-family: 'Font Awesome 5 Free';
//        font-weight: 900;
//        font-size: inherit;
//		text-rendering: auto;
//		-webkit-font-smoothing: antialiased;
//		-moz-osx-font-smoothing: grayscale;
//		content: "\f0c8";
//
//		margin-left: -22px;
//		width: 22px;
//	}
//}

.well {
	border-radius: 0;
}

ul.unformatted {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul li {
    margin-bottom: 0.75rem;
}

ul.list-style-check {
    list-style: none;
    padding: 0 !important;
    margin: 0;
    li {
        padding-left: 1.3rem;
        margin-left: 1rem;
        margin-bottom: 0.75rem;
        &:before {
            font-family: 'Font Awesome 6 Free';
            font-weight: 900;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            content: "\f00c";
            margin-left: -2rem;
            padding-right: 1rem;
        }
        &.clone:before {
            content:"\f24d";
        }
        &.exclamation:before {
            content:"\f06a";
        }
    }
}

ol {
    margin-top: 0;
    margin-bottom: 0;
    li {
        padding-left: 10px;
        &::marker {
            padding-left: 0;
            font-weight: 600;
        }
    }
}


//
// Forms
// --------------------------------------------------
.ssselect-wrapper {
  position: relative;
  width: 100%;
}
.ssselect-wrapper:after {
  display: inline-block;
	float: right;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\f054";
	-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);

  width: 0;
  height: 0;
  //border-left: 5px solid transparent;
  //border-right: 5px solid transparent;
  //border-top: 6px solid #666;
  position: absolute;
  right: 8px;
  top: 17px;
  pointer-events: none;
}
select {
	border-radius: 0px;

}
ssselect::-ms-expand {
  display: none;
}

.has-success .form-control {
	border-color: inherit;
}

//$media(max-width: $screen-md-max) {
// removed:
// .ce-gallery
@include media-breakpoint-down(lg) {
	.ce-textpic, .ce-image, .ce-nowrap .ce-bodytext, .ce-row, .ce-uploads li, .ce-uploads div {
		overflow: inherit;
	}
}

.position-right {
    right: 0;
}
.position-left {
    left: 0;
}
.position-top {
    top: 0;
}
.position-bottom {
    bottom: 0;
}


// accordion
.accordion-item {
    @extend .mb-4;
    .accordion-body {
        background-color: $body-bg;
        //p {
        //    margin: 0;
        //}
    }
}

// image
img {
    border-radius: $border-radius;
}

.fa-15x {
    font-size: 1.5em;
}



.link-icon-page {
    margin-left: 50px;
    &:before {
        content: url("data:image/svg+xml,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 122.88 122.88'><defs><style>.cls-1{fill-rule:evenodd;}</style></defs><title>euro-sign</title><path class='cls-1' d='M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0ZM74.79,51.93l-3,6.59H54.72c0,.95-.09,2-.09,3s0,1.93.08,2.83H69l-3.35,6.78H55.81c.09.28.18.55.28.82a11,11,0,0,0,4.12,5.68,11.51,11.51,0,0,0,6.43,1.76,15.19,15.19,0,0,0,4.6-.67,18.07,18.07,0,0,0,3.49-1.54c1-.57,1.77-1,2.33-1.45l5.35,11.33a22.61,22.61,0,0,1-7.24,3.81,28,28,0,0,1-8.53,1.27,26.47,26.47,0,0,1-13.8-3.58,24.47,24.47,0,0,1-9.39-10.45,30.86,30.86,0,0,1-2.41-7H32.45l2.48-6.78h5.21c0-.92-.07-1.87-.07-2.83s0-2,.09-3H32.45l2.48-6.59H41a31.37,31.37,0,0,1,2.41-7,24.83,24.83,0,0,1,9.39-10.54,26,26,0,0,1,13.8-3.64,26.87,26.87,0,0,1,8.68,1.34,25.41,25.41,0,0,1,7.29,3.83L77.3,47.18c-.64-.34-1.44-.82-2.44-1.41a20.48,20.48,0,0,0-3.56-1.59,14.63,14.63,0,0,0-4.66-.69,11.15,11.15,0,0,0-6.43,1.82,11.44,11.44,0,0,0-4.12,5.8c-.1.26-.19.54-.28.82ZM61.44,10.48a51,51,0,1,1-51,51,51,51,0,0,1,51-51Z'/></svg>");
        width: 35px;
        height: 35px;
        display: inline-block;
        margin-left: -50px;
        margin-right: 15px;
        // color: $primary;
        // padding-right: 0.5rem;
        // font-size: 1.4rem;
        // content: "\f35d";
        // box-sizing: border-box;
        // -webkit-font-smoothing: antialiased;
        // display: var(--fa-display, inline-block);
        // font-style: normal;
        // font-variant: normal;
        // line-height: 1;
        // text-rendering: auto;
        // font-family: "Font Awesome 6 Free";
        // font-weight: 900;
    }
}
