// ! bootstrap has two transition mixins too... maybe they are enough
@mixin transition-theme-2($type, $time1, $easing, $time2)
{
    -webkit-transition: $type $time1 $easing $time2;
    -moz-transition: $type $time1 $easing $time2;
    -ms-transition: $type $time1 $easing $time2;
    -o-transition: $type $time1 $easing $time2;
    transition: $type $time1 $easing $time2;
}


