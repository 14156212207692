// global
.form-control {
	// height: $input-height-lg;
    height: auto !important;
}
input.powermail_submit {
	text-transform: uppercase;
}

.form-check .form-check-input {
    margin-left: -1.75em;
}

.tx-powermail {
	.container-fluid {
		padding: 0;
	}

	fieldset.powermail_fieldset {
		.powermail_fieldwrap {
			select,
			textarea,
			input {
				&.parsley-error {
					border: 2px solid $primary;
					background-color: #fff;
				}
			}
			clear: both;
			&.layout1 {
				clear: none;
				width: 50%;
				float: left;
				margin-left: 0;
				margin-right: 0;
				&:nth-child(2n-2) {
					padding-right: 10px;
				}

				.control-label {
                    @extend .sr-only;
				}
				div[class*="col-"] {
					width: 100%;
					padding: 0;
				}
			}
			&.nolabel {
				div[class*="col-"] {
					width: 100%;
					margin-left: 0;
				}
			}
		}
	}
	// form.layout1 {
	// 	.powermail_fieldset {
	// 		min-height: 800px;
	// 		float: left;
	// 		width: 49%;
	// 		display: inline-block;
    //         //display: flex;
	// 		padding: 50px 35px 25px 35px;
	// 		margin-right: 1%;
	// 		margin-bottom: 50px;
	// 		background-color: $gray-lighter;
	// 		&:nth-child(2n+3) {
	// 			//background-color: $gray-lighter;
	// 			margin-right: 0;
	// 			margin-left: 1%;
	// 		}

	// 		//$media (max-width: $screen-sm-max) {
    //         @include media-breakpoint-down(md) {
	// 			width: 100%;
    //             margin-right: 0;
	// 		}
	// 		legend {
	// 			position: relative;
	// 			top: -20px;
	// 			border: 0;
	// 			text-transform: uppercase;
	// 			font-size: $font-size-base;
	// 			margin: 0;
	// 		}
	// 		&.nolabel {
	// 			padding-top: 15px;
	// 			legend {
	// 				top: 0;
	// 			}
	// 		}
	// 		&.layout1 {
	// 			//padding-left: 0;
	// 			legend {
	// 				font-size: $h3-font-size;
	// 				line-height: 1;
	// 				font-weight: 700;
	// 				//padding-left: $grid-gutter-width/2;
	// 				//padding-right: $grid-gutter-width/2;
	// 			}
	// 		}
	// 		.form-group {
	// 			margin-left: 0;
	// 			margin-right: 0;
    //             display: flex;
    //             flex-direction: column;
	// 			> label {
	// 				font-weight: 300;
	// 				text-transform: uppercase;
	// 				//padding-left: $grid-gutter-width/2;
	// 				padding-right: $grid-gutter-width/2;
	// 			}
	// 		}

	// 		.powermail_fieldwrap_type_radio,
	// 		.powermail_fieldwrap_type_check {
	// 			div.radio,
	// 			div.checkbox {
	// 				float: left;
	// 				margin-right: 25px;
	// 				line-height: 1.3;
	// 				label {
	// 					font-weight: 300;
	// 				}
	// 			}
	// 		}
	// 		.powermail_fieldwrap_type_submit {
	// 			input {

	// 				width: auto;
	// 			}
	// 		}
	// 		&:first-of-type {
	// 			.form-group {
	// 				width: 100%;
	// 				float: left;
	// 				//margin-left: 0;
	// 				//margin-right: 0;
	// 			}
	// 			//.powermail_fieldwrap_firma,
	// 			//.powermail_fieldwrap_interesse,
	// 			//.powermail_fieldwrap_persoenlicheangaben,
	// 			//.powermail_fieldwrap_nachname,
	// 			//.powermail_fieldwrap_strasse,
	// 			//.powermail_fieldwrap_email,
	// 			//.powermail_fieldwrap_zustzlicheinformationen,
	// 			//.powermail_fieldwrap_informationen {
	// 			//	width: 100%;
	// 			//	float: none;
	// 			//	clear: both;
	// 			//}
	// 		}
	// 	}
	// 	&.powermail_form_6 {
	// 		.powermail_fieldset {
	// 			min-height: 950px;
	// 		}
	// 		.powermail_fieldwrap_land {
	// 			width: 100% !important;
	// 			float: none;
	// 		}
	// 		// .powermail_fieldwrap_anzahldermitarbeiter,
	// 		// .powermail_fieldwrap_seitwann,
	// 		// .powermail_fieldwrap_anzahleingebauterwrmepumpen {
	// 		// 	width: 33.333334%;
	// 		// 	float: left;
	// 		// }
	// 	}
    //     &.powermail_form_44 {
    //         &:first-of-type {
    //             .form-group {
    //                 width: 100%;
    //                 float: none;
    //                 clear: both;
    //             }
    //         }
    //     }
	// }

    form.layout1,
	form.layout2 {
		width: 100%;
		position: relative;
		margin: 0 auto;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		justify-content: space-between;
		//$media (max-width: $screen-sm-max) {
        @include media-breakpoint-down(md) {
			flex-direction: column;
			justify-content: center;
		}
		fieldset {
			flex-basis: 0;
			flex-grow: 1;
			margin-bottom: 50px;
			background-color: $gray-lighter;
			padding: 30px;
			//$media (max-width: $screen-sm-max) {
            @include media-breakpoint-down(md) {
				margin-left: 0;
				margin-right: 0;
				flex: 0 0 100%;
			}
			&:first-of-type {
				margin-right: 3%;
				//$media (max-width: $screen-sm-max) {
                @include media-breakpoint-down(md) {
					margin-right: 0;
				}
			}
			legend {
				display: block;
				width: auto;
				padding: 0;
				margin-bottom: 27px;
				font-size: 24px;
				line-height: inherit;
				color: #383838;
				border: 0;
				padding: 8px 25px;
				background-color: $primary;
				color: white;
			}
			.powermail_fieldwrap {
				label {
					padding-left: $grid-gutter-width/2;
					padding-right: $grid-gutter-width/2;
				}
				.radio,
				.checkbox {
					label {
						padding-left: 25px;
					}
					input.powermail_radio {
						//margin-left: -10px;
					}
				}
				&.powermail_fieldwrap_type_submit {
					margin-top: 40px;
				}
				&.powermail_fieldwrap_type_captcha {
					input {
						width: 45%;
						float: left;
						margin-right: 10px;
						margin-bottom: 4px;
					}
				}
			}
		}

	}
	form.layout3 {
		width: 100%;
		position: relative;
		margin: 0 auto;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
		justify-content: center;
		fieldset {
			width: 75%;
			margin: 0 auto;
			margin-bottom: 50px;
			background-color: $gray-lighter;
			padding: 30px;
			//$media (max-width: $screen-sm-max) {
            @include media-breakpoint-down(md) {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
			&:first-of-type {
			}
			legend {
				display: block;
				width: auto;
				padding: 0;
				margin-bottom: 27px;
				font-size: 24px;
				line-height: inherit;
				color: #383838;
				border: 0;
				padding: 8px 25px;
				background-color: $primary;
				color: white;
			}
			.powermail_fieldwrap {
				label {
					padding-left: $grid-gutter-width/2;
					padding-right: $grid-gutter-width/2;
				}
				.radio,
				.checkbox {
					label {
						padding-left: 25px;
					}
					input.powermail_radio {
						//margin-left: -10px;
					}
				}
				&.powermail_fieldwrap_type_submit {
					margin-top: 40px;
				}
				&.powermail_fieldwrap_type_captcha {
					input {
						width: 45%;
						float: left;
						margin-right: 10px;
						margin-bottom: 4px;
					}
				}
			}
		}

	}
    form.layout4 {
        .powermail_fieldwrap {
            &.powermail_fieldwrap_anrede,
            &.powermail_fieldwrap_vorname,
            &.powermail_fieldwrap_nachname,
            &.powermail_fieldwrap_land,
            &.powermail_fieldwrap_email,
            &.powermail_fieldwrap_telefon {
                width: 50%;
                float: left;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    float: none;
                }
            }
            &.powermail_fieldwrap_plz {
                width: 33%;
                float: left;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    float: none;
                    padding-right: 0 !important;
                }
            }
            &.powermail_fieldwrap_ort {
                width: 67%;
                float: left;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    float: none;
                }
            }
            &.powermail_fieldwrap_nachname,
            &.powermail_fieldwrap_ort,
            &.powermail_fieldwrap_email {
                clear: none;
                padding-left: 30px;
                @include media-breakpoint-down(md) {
                    clear: both;
                    padding-left: 0;
                }
            }
        }
    }
}

ul.parsley-errors-list {
	background: none !important;
	border: 0 !important;
}

.page-footer {
    .form-floating {
        .form-control {
            padding-top: 1.625rem;
            padding-bottom: 0.625rem;
        }
        label {
            color: $body-color;
        }
    }
	.tx-powermail {
		.powermail_fieldwrap {
            &.powermail_fieldwrap_anrede,
            &.powermail_fieldwrap_email,
            &.powermail_fieldwrap_land {
                clear: both;
				width: 50%;
				float: left;
				margin-left: 0;
				margin-right: 0;
                padding-left: 0 !important;
				// 	padding-right: 10px;
            }
            &.powermail_fieldwrap_vorname,
            &.powermail_fieldwrap_telefon,
            &.powermail_fieldwrap_plz {
                clear: none;
				width: 50%;
				float: left;
				margin-left: 0;
				margin-right: 0;
                padding-left: 10px;
            }

			&.layout1 {
				// &:nth-child(2n-3) {
				// 	padding-left: 10px;
				// }
				// &:nth-child(2n-2) {
				// 	padding-left: 0 !important;
				// 	padding-right: 10px;
				// }
			}
			a {
				color: $color-gray-lighter-2;
			}
		}
		input:not(.powermail_submit, .form-check-input),
		select,
		textarea {
			background-color: #fff;
		}
		input.powermail_submit {

		}
		.powermail_progressbar {
			margin-top: 20px;
		}
		.progress-bar {
			background-color: $primary;
		}
	}
}
