.tx-ids-slider {
    .ids-slider {
        p {
            line-height: 1.1;
        }
        .ids-slider-item {
            @include media-breakpoint-down(sm) {
                background-color: $primary;
            }
            .ids-slider-item-content {
                position: absolute;
                display: flex;
                height: 100%;
                flex-wrap: nowrap;
                align-items: center;
                flex-direction: row;
                top: 0;
                width: 100%;
                @include media-breakpoint-down(sm) {
                    position: initial;
                    top: initial;
                    height: auto;
                    align-items: flex-start;
                    overflow: hidden;
                }
                &.flex-reverse {
                    flex-direction: row-reverse;
                }

                p {
                    margin: 0;
                }

                .ids-slider-item-text,
                .ids-slider-item-image {
                    .ids-slider-item-image-inner {
                        flex: 0 0 auto;
                        max-width: 100%;
                        width: 100%;
                        @include media-breakpoint-down(sm) {
                            padding-top: 1em;
                            padding-bottom: 1em;
                            text-align: center;
                            display: block !important;
                        }
                        p:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }

                .ids-slider-item-text {
                    margin-left: 5rem;
                    width: 52%;
                    @include media-breakpoint-down(xxl) {
                        //width: 500px;
                    }
                    @include media-breakpoint-down(sm) {
                        margin-left: 0;
                        width: 100%;
                    }
                    .ids-slider-title,
                    .ids-slider-content {
                        float: left;
                        @include media-breakpoint-down(sm) {
                            float: none;
                            clear: both;
                        }

                    }
                }

                .ids-slider-item-text-inner.fade {
                    opacity: 0;
                    transition: opacity 400ms linear;

                    &.fade-in {
                        opacity: 1;
                        // transition: none;
                    }
                }
            }
        }
    }

    .bg-primary-darker {
        background-color: #89130F;
    }


    //.swiper-pagination .swiper-pagination-bullet {
    //    width: 14px;
    //    height: 14px;
    //    margin: 0 5px;
    //    background: transparent;
    //    opacity: 1;
    //    border: 1px solid $primary;
    //
    //    &.swiper-pagination-bullet-active {
    //        background: $primary;
    //    }
    //}
    //
    //.swiper-pagination {
    //    @include media-breakpoint-down(sm) {
    //        bottom: -20px;
    //    }
    //}
    //
    //&.swiper-container {
    //    //overflow: visible;
    //}
}
