.modal-backdrop.fade:not(.show) {
    visibility: hidden;
}

.modal {
    z-index: 9999;
    .modal-dialog {
        .modal-header {
            position: relative;
        }
        button.btn-close-self {
            &:hover {
                color: $body-color;
                .fa-stack-1x {
                    color: $white !important;
                }
            }
        }
        button.btn-close-own {
            display: none;
            z-index: 9;
            //top: 45px;
            margin-left: 844px;
            @include media-breakpoint-down(xl) {
                margin-left: 514px;
            }
            @media (max-width: 1024px) {
                margin-left: 559px;
            }
            @include media-breakpoint-down(md) {
                margin-left: 0;
                right: 20px;
                top: 20px;
            }
            @include media-breakpoint-down(sm) {
                margin-left: 0;
                right: 0;
                top: 20px;
            }
            &:hover {
                color: $body-color;
                .fa-stack-1x {
                    color: $white !important;
                }
            }
        }
        &.modal-lg {
            button.btn-close-own {
                //top: 45px;
                //margin-left: 844px;
                margin-left: 695px;
                @include media-breakpoint-down(xl) {
                    //margin-left: 514px;
                    margin-left: 674px;
                }
                @media (max-width: 1024px) {
                    margin-left: 570px;
                }
                @include media-breakpoint-down(md) {
                    margin-left: 0;
                    right: 20px;
                    top: 20px;
                }
                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                    right: 0;
                    top: 20px;
                }
            }
        }
    }
    &.show {
        .modal-dialog {
            button.btn-close-own {
                margin-top: 12px;
                display: block;
            }
        }
    }
}

.modal-body-wrapper {
    width: 85%;
    .container-fluid .article.ce-layout-0 {
        width: 100% !important;
    }
}
