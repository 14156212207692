.sidebar-navigation {
    position: fixed;
    right: 0;
    top: 250px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    //@media(max-width: @screen-xs-max) {
    @include media-breakpoint-down(sm) {
        top: 100px;
    }
    .sidebar-navigation-item {
        display: block;
    }

    .sidebar-navigation-item-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3em;
        height: 3em;
        background-color: $primary;
        margin: .25em 0;
        transition: width .25s;
    }

    .sidebar-navigation-item-icon {
        font-size: 2em;
        color: $color-white;
    }

    .sidebar-navigation-item:hover .sidebar-navigation-item-inner {
        width: 3.5em;
    }
}

.toggle-sidebar-menu {
    position: absolute;
    top: 29px;
    right: -44px;
    background: $primary;
    padding: 9px 14px;
    color: $white;
    cursor: pointer;
    @media(max-width: 1024px) {
        right: -41px;
    }
    @media(max-width: 767px) {
        right: -44px;
    }
    &:hover {
        color: $white;
    }
}

.sidebar-menu {
    max-width: $sideBarWidth;
    @include media-breakpoint-down(xxl) {
        max-width: $sideBarWidth-xl;
    }
    @include media-breakpoint-down(xl) {
        max-width: $sideBarWidth-lg;
    }
    @include media-breakpoint-down(lg) {
        max-width: $sideBarWidth-md;
    }
    @include media-breakpoint-down(md) {
        max-width: $sideBarWidth-sm;
    }
    position: relative;
    z-index: 999;
    .sticky-menu {
        position: sticky;
        top: 160px;
        @include media-breakpoint-down(md) {
            top: 97px;
        }
    }
    @include media-breakpoint-down(xl) {
        &:not(.blog-sidebar):not(.pim-sidebar) {
            display: none;
        }

        max-width: 100%;
        position: fixed;
        width: $sideBarWidth;
        height: 100vh;
        left: -$sideBarWidth;
        top: 53px;
        background: $white;
        //transform: translateY(-50%);
        transition: left 0.3s ease-in-out, left 0.3s ease-in-out;
        padding-right: 0 !important;
        padding-left: 0 !important;
        @include media-breakpoint-down(xxl) {
            width: $sideBarWidth-xl;
            left: -$sideBarWidth-xl;
        }
        @include media-breakpoint-down(xl) {
            width: $sideBarWidth-lg;
            left: -$sideBarWidth-lg;
        }
        @include media-breakpoint-down(lg) {
            width: $sideBarWidth-md;
            left: -$sideBarWidth-md;
        }
        @include media-breakpoint-down(md) {
            width: $sideBarWidth-sm;
            left: -$sideBarWidth-sm;
        }
        &.show {
            left: 0;
        }
        .sticky-menu {
            z-index: 9;
            top: initial;
            margin-top: 30px;
            position: initial;
            height: 100%;
            box-shadow: 0px 10px 10px $gray-500;
            padding-left: $grid-gutter-width/2;
        }
    }
    a {

        &.active,
        &:hover:not(.toggle-sidebar-menu, .pim-category-link) {
            color: $primary !important;
            &:after {

                font-family: 'Font Awesome 6 Free';
                font-weight: 900;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                content: "\f105";
                @extend .me-2;
            }
        }
    }
}
