/* Slider */

.slick-arrow {
	position: absolute;
	top: 50%;
	cursor: pointer;
	color: $primary;
	font-size: $font-size-large-6;
	margin-top: -($font-size-large-6/2);
	z-index: 9;
}
.slick-prev {
	left: ($grid-gutter-width/2);
}
.slick-next {
	right: ($grid-gutter-width/2);
}

.slick-slider {
	padding-bottom: 50px;
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex !important;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: inherit !important;
    min-height: 1px;
    > div {
        height: 100%;
    }
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-dots {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
		&:before {
			content: " " !important;
		}
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 14px;
      height: 14px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      &:before {
        font-family: slick;
        font-size: 30px;
        line-height: 20px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 14px;
        height: 14px;
        content: "•";
        text-align: center;
        opacity: 1;
        color: transparent;
				border: 1px solid $primary;
				border-radius: 100%;
        -webkit-font-smoothing: antialiased;
      }
    }
    &.slick-active {
      button:before {
			background-color: $primary;
      }
    }
  }
}

.ce-menu-thumbnail-list {
	visibility: hidden;
	&.slick-initialized {
		visibility: visible;
		@include transition(visibility .8s ease-in 0s);
	}
}

.news-list-view-101 {
	.slick-slider {
		padding-bottom: 0;
	}
	.news-list-view-101-items {
		visibility: hidden;
		&.slick-initialized {
			visibility: visible;
			@include transition(all .8s ease-in 0s);
		}
		ul.slick-dots {
			top: 10px;
			bottom: inherit;
			right: 10px;
			width: inherit;
			li {
				display: block;
				margin-bottom: 6px !important;
			}
		}
	}
}
