// .btn:not(.navbar-toggle) {
//     position: relative;
//     @include media-breakpoint-up(sm) {
//         &:after {
//             position: absolute;
//             font-family: 'Font Awesome 6 Free';
//             font-weight: 900;
//             -moz-osx-font-smoothing: grayscale;
//             -webkit-font-smoothing: antialiased;
//             display: inline-block;
//             font-style: normal;
//             font-variant: normal;
//             text-rendering: auto;
//             line-height: 1;
//             content: "\f054";
//             //right: 60px;
//             padding-left: 1rem;
//             //padding-top: .2rem;
//             top: 50%;
//             transform: translateY(-50%);
//             transition: padding-left 0.3s ease-in-out, left 0.3s ease-in-out;
//         }
//         &:hover {
//             &:after {
//                 padding-left: 1.25rem;
//             }
//         }
//     }
//     //@include media-breakpoint-down(sm) {
//     //    padding-left: 0;
//     //    padding-right: 0;
//     //}
// }

.btn {
    text-transform: uppercase;
}

// .btn.btn-arrow-sm {
//     padding-left: 1.25rem;
//     padding-right: 2.5rem;
//     padding-top: .5rem;
//     padding-bottom: .5rem;
//     white-space: nowrap;
//     border-width: 2px;
//     @extend .font-weight-semibold;
//     @extend .font-size-larger-1;
//     &:after {
//         padding-left: .75rem;
//     }
//     &:hover {
//         &:after {
//             padding-left: 1rem;
//         }
//     }
//     @include media-breakpoint-down(xl) {
//         font-size: 1rem !important;
//     }
// }

.btn-outline-secondary {
    border-color: #fff;
    color: #fff;
    &:hover {
        border-color: $gray-100;
        color: $gray-100;
        background-color: transparent;
    }
}
