.page-media {
	//margin-top: 62px;
	margin-bottom: 50px;
	//@media(max-width: 1600px) {
	//	margin-top: 88px;
	//}
	//@media(max-width: 1300px) {
	//	margin-top: 80px;
	//}
	//@media(max-width: 1200px) {
	//	margin-top: 77px;
	//}
	//@media(max-width: 1024px) {
	//	margin-top: 80px;
	//}
    //@include media-breakpoint-down(md) {
	//	margin-top: 53px;
	//}
	.article-ext {
		&.ce-layout-1 {
			.container-fluid {
				max-width: none;
				width: 100%;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	//
	//.ls-layers {
	//	.ls-layer.h5 {
	//		font-size: 72px;
	//	}
	//	.ls-layer.h6 {
	//		.h6() !important;
	//	}
	//}
}

.backendlayout-pagets__default {
	.page-media {
		display: block;
		width: 100%;
		background: $body-bg;
		margin-bottom: 60px;
		//margin-top: 74px;
		//@media(max-width: 1600px) {
		//	margin-top: 94px;
		//}
        //@media(max-width: 1300px) {
        //    margin-top: 93px;
        //}
        //@media(max-width: 1024px) {
        //    margin-top: 91px;
        //}

        //@media(max-width: 1200px) {
        //    margin-top: 93px;
        //}
        //@media(max-width: 1100px) {
        //    margin-top: 93px;
        //}

        //@include media-breakpoint-down(md) {
        //    margin-top: 51px;
        //}
		.carousel-indicators {
			margin-bottom: 0;
			bottom: 4px;
			right: 14%;
			left: auto;
			width: auto;
			margin-left: 0;
			li {
				width: 12px;
				height: 12px;
				border-radius: 12px;
				margin: 2px;
				text-indent: -999em;
				overflow: hidden;
			}
		}
		.carousel-inner {
			.item {
				position: relative;
				.container-fluid {
					position: relative;
				}
				.mediaItem {
					padding-bottom: 30%;
					background-repeat: no-repeat;
					background-position: right top;
					background-size: cover;
					//@media(max-width: $screen-sm-max) {
                    @include media-breakpoint-down(md) {
						padding: 0;
						height: 305px;
					}
				}
			}
		}
		.carousel-fade {
			.carousel-inner {
				.item {
					opacity: 0;
					transition-property: opacity;
					&.active {
						opacity: 1;
					}
				}
				.active.left,
				.active.right {
					left: 0;
					opacity: 0;
					/*z-index: 1;*/
				}

				.next.left,
				.prev.right {
					opacity: 1;
				}
			}
		}
		.carousel-control {
			opacity: 1;
			background: none;
			text-shadow: none;
		}
		.fa {
			font-size: 10em;
			top: 50%;
			margin-top: -100px;
			position: absolute;
			left: 50%;
			margin-left: -17.5px;
			color: rgba(255,255,255,0.75);
			&:hover {
				color: rgba(200,200,200,0.75);
				@include transition-theme-2(color, $nav-main-duration-time, ease, $nav-main-transition-time);
			}
		}
		.carouselItemContentWrapper {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 100%;
			//@media(max-width: $screen-xs-max) {
            @include media-breakpoint-down(sm) {
				position: relative;
			}
			.container-fluid {
				position: relative;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				//@media(max-width: $screen-xs-max) {
                @include media-breakpoint-down(sm) {
					top: 0;
					-webkit-transform: translateY(0);
					-ms-transform: translateY(0);
					transform: translateY(0);
				}
				.carouselItemContent {
					display: block;
					width: 50%;
					//@media(max-width: $screen-md-max) {
                    @include media-breakpoint-down(lg) {
						width: 70%;
					}
					//@media(max-width: $screen-sm-max) {
                    @include media-breakpoint-down(md) {
						width: 100%;
						text-align: center;
						margin-top: 30px;
					}
					.carouselItemHeader {
						display: inline-block;
					}
					.carouselItemHeader,
					.carouselItemSubHeader {
						.headline {
							font-size: $h1-font-size;
						}
						.subheader {
							font-size: $font-size-large-1;
							margin-bottom: 0;
						}
						//@media(max-width: $screen-md-max) {
                        @include media-breakpoint-down(lg) {
							.headline {
								font-size: $font-size-large-4;
							}
							.subheader {
								//font-size: $font-size-large-1;
							}
						}
						//@media(max-width: $screen-xs-max) {
                        @include media-breakpoint-down(sm) {
							.headline {
								color: $gray-dark !important;
							}
							.subheader {
								color: $gray-dark !important;
							}
						}
					}
					.carouselItemBodytext{
						clear: both;
						display: inline-block;
						position: relative;
						margin-top: 25px;
						font-size: 20px;
						color: $gray-dark;
						.headline {
							font-size: $h1-font-size;
						}
						.subheader {
							font-size: $font-size-large-1;
						}
						//@media(max-width: $screen-md-max) {
                        @include media-breakpoint-down(lg) {
							margin-top: 0;
							.headline {
								font-size: $font-size-large-4;
							}
							.subheader {
								//font-size: $font-size-large-1;
							}
						}
						//@media(max-width: $screen-xs-max) {
                        @include media-breakpoint-down(sm) {
							margin-top: 0;
							.headline {
								color: $gray-dark !important;
							}
							.subheader {
								color: $gray-dark !important;
							}
						}
					}
				}
			}
		}
	}
}

.backendlayout-pagets__special_start {
	.page-media {
		.flexslider {
			margin: 0;
			border: 0;
			.slider-image {
				margin-bottom: 25px;
			}
			.slider-header {
				font-size: $font-size-large-2;
				font-weight: 700;
				margin-bottom: 15px;
				text-align: center;
			}
			.slider-content {
				text-align: center;
				padding: 0 20px;
			}
			.flex-control-paging {
				li {
					a {
						width: 15px;
						height: 15px;
						background: none;
						border: 1px solid $primary;
						box-shadow: none;
						&.flex-active {
							background: $primary;
						}
					}
				}
			}
		}
	}
}
