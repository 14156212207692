

$border-color: $gray-light;
$border-box-radius: 0;
$color-ratingstars: $primary;



.testimonials-list {
	margin-left: -20px;
	margin-right: -20px;
	padding: 50px 50px 15px 50px;
	//$media (max-width: $screen-md-max) {
    @include media-breakpoint-down(lg) {
		padding-left: 25px;
		padding-right: 25px;
	}
	.testimonials-box-wrapper {
		padding: 0 10px;

		background-color: #fff;
		//$media (max-width: $screen-md-max) {
        @include media-breakpoint-down(lg) {
			margin: 0 10px;
		}
		@media (max-width: 991px) {
			margin: 0;
		}
	}
	.testimonials-box {
		padding: 0;
		position: relative;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-radius: $border-box-radius;
		.testimonials-header {
			color: #fff;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			font-size: $font-size-sm;
			padding: 4px 0;
			background: #4a4a4a;
			border-top-left-radius: $border-box-radius;
			border-top-right-radius: $border-box-radius;

			@media (max-width: 768px) {
				font-size: $font-size-lg;
			}
			i.fa {
				margin-left: 10px;
			}
		}
		.testimonials-rating {
			font-size: 26px;
			line-height: 34px;
			padding-top: 15px;
			padding-bottom: 15px;
			.testimonial-star {
					float: left;
					width: 20%;
					text-align: center;
			}
			i.fa {
				color: $color-ratingstars;
				font-size: 34px;
			}
			@media (max-width: 1439px) {
					font-size: 20px;
					line-height: 28px;
					i.fa {
							font-size: 28px;
					}
			}
			@media (max-width: 1199px) {
				font-size: 20px;
				line-height: 28px;
				i.fa {
					font-size: 28px;
				}
			}
			@media (max-width: 768px) {
				font-size: 10px;
				line-height: 26px;
				i.fa {
					font-size: 26px;
				}
			}
		}
		.testimonials-rating,
		.testimonials-content,
		.testimonials-personalinfo {
			padding-top: 15px;
			padding-bottom: 15px;
			//border-left: 1px solid $border-color;
			//border-right: 1px solid $border-color;
		}
		.testimonials-line {
			hr {
				margin: 0;
			}
		}
		.testimonials-content {
			//border-top: 1px solid $border-color;
			font-size: $font-size-base;
            padding: 10px 15px;
		}

		.testimonials-personalinfo {
            padding: 10px 15px;
		    font-size: $font-size-sm;
		}
	}
	ul.slick-dots {
		position: relative;
		bottom: inherit;
		margin-top: 30px;
	}
}

.testimonials-latest {
	display: inline-block;
	margin-top: 80px;
    @include media-breakpoint-down(md) {
        .border-start {
            border-left: 0 !important;
        }
    }
	h2 {
		&:after {
			content: " ";
			width: 40px;
			height: 8px;
			background-color: $text-color;
			display: block;
			margin-top: 20px;
		}
		margin-bottom: 25px;
		letter-spacing: 2px;
	}
	.testimonials-average-stars {
		.testimonial-star {
			margin-bottom: 20px;
				float: left;
				width: 20%;
				text-align: center;
		}
		i.fa {
			color: $color-ratingstars;
			font-size: 36px;
			@media (max-width: 1400px) {
				font-size: 30px;
			}
			@media (max-width: 1200px) {
				font-size: 26px;
			}
			//$media (max-width: $screen-sm-max) {
            @include media-breakpoint-down(md) {
				font-size: $font-size-large-6;
			}
		}
		.testimonials-personalinfo {
			font-weight: 700;
		}
		//$media (max-width: $screen-sm-max) {
        @include media-breakpoint-down(md) {
			margin-bottom: 40px;
		}
	}
	.testimonials-average-info {
		//$media (max-width: $screen-sm-max) {
        @include media-breakpoint-down(md) {
			font-size: $font-size-large-2;
			text-align: center;
			font-weight: 700;
			padding-bottom: 50px;
			margin-bottom: 50px;
			border-bottom: 1px solid $gray-lighter;
		}
	}
	.testimonials-box {
		margin-bottom: 50px;
		padding: 0;
		position: relative;
		//$media (max-width: $screen-xs-max) {
        @include media-breakpoint-down(sm) {
			border-bottom: 1px solid $gray-lighter;
			padding-bottom: 50px;
		}
		.testimonials-rating {
			.testimonial-star {
				margin-bottom: 20px;
				float: left;
				width: 20%;
				text-align: center;
			}
			i.fa {
				color: $color-ratingstars;
				font-size: 40px;
				@media (max-width: 1400px) {
					font-size: 34px;
				}
				@media (max-width: 1200px) {
					font-size: 28px;
				}
				//$media (max-width: $screen-xs-max) {
                @include media-breakpoint-down(sm) {
					font-size: $font-size-large-6;
				}
			}
			.testimonials-personalinfo {
				font-weight: 700;
				//$media (max-width: $screen-xs-max) {
                @include media-breakpoint-down(sm) {
					text-align: center;
					margin-bottom: 15px;
				}
			}
		}
		.testimonials-content {
			.quote {
				i.fa {
					font-size: $font-size-large-6;
					color: $gray-light;
					@media (max-width: 1400px) {
						font-size: 48px;
					}
					@media (max-width: 1200px) {
						font-size: 38px;
					}
				}
			}
		}
	}
	.testimonials-more {
		margin-top: 25px;
		margin-bottom: 25px;
		font-weight: 700;
		//$media (max-width: $screen-xs-max) {
        @include media-breakpoint-down(sm) {
			text-align: center;
			font-size: $font-size-large;
			margin-bottom: 0;
		}
	}
}
