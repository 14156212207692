.tx-idw-bwvgl,
.tx-idw-hkvgl {

	form.bw_form {
		.well {
			border: 0;
			box-shadow: none;
			padding: 35px;
		}
		label {
			text-align: left;
			font-weight: 300;
		}
		.hkvgl-fieldset-title {
			font-size: $h3-font-size;
			font-weight: 700;
			margin-bottom: 15px;
			text-transform: uppercase;
		}
		.hkvgl-fieldset {
			.hkvgl_country {
				display: none;
				&.active {
					display: block;
				}
			}
			.hkvgl-fieldset-title {
				font-size: $font-size-base;
			}
			input[readonly] {
				background: none;
				border: 0;
				font-weight: 700;
			}
			input.no-border {
				border: 0;
				box-shadow: none;
			}
			input.equal-height {
				padding: 0;
				margin: 0;
				height: auto;
			}
			input[type="radio"],
			input[type="checkbox"] {
				&.form-control,
				&:focus {
					box-shadow: none;
					border: 0;
					outline: none;
				}
			}
		}
	}
	.auswertung {
		display: none;
		.panel-body {
			padding: 35px;
			input[readonly] {
				border: 0;
			}
		}
	}
}
