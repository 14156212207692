.tx-ids-oxomi {
    margin-top: 2rem;
    .oxomi-catalog {
        width: 100%;
        height: auto;
        margin-right: 0 !important;
        .oxomi-link {
            top: initial;
            transform: initial;
        }
    }
}
