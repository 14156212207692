blockquote {
    width: 75%;
    margin: 0 auto;
    padding-left: 2rem;
    border-left: 6px solid $primary;
    font-weight: bold;
    @extend .font-size-larger-3;
    @extend .line-height-larger-5;
    @media(max-width: 1024px) {
        font-size: 20px !important;
    }
}
