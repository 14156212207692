//
// Cookie bar
// ------------------------------------------------------------- //
#cookie-bar {
	position: relative;
	background: $gray-light;
	height: auto;
	line-height: 28px;
	text-align: left;
  padding: 20px 140px 20px 20px;
  z-index: 99999;
  font-size: $font-size-sm;
  color: #fff;
}
#cookie-bar.fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}
#cookie-bar.fixed.bottom {
	bottom: 0;
	top: auto;
}
#cookie-bar p {
	margin: 0;
	padding: 0;
}
#cookie-bar a {
	color: #ffffff;
	display: inline;
	border-radius: 3px;
	text-decoration: none;
	padding: 4px 6px;
	margin-left: 8px;
}
#cookie-bar .cb-enable {
	background: none;
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -15px;
	padding: 0px 16px;
	border-radius: 0;
	border: 1px solid $text-color;
}
#cookie-bar .cb-enable:hover {
	//background: #009900;
}
#cookie-bar .cb-disable {
	background: #990000;
}
#cookie-bar .cb-disable:hover {
	background: #bb0000;
}
#cookie-bar .cb-policy {
	background: #0033bb;
}
#cookie-bar .cb-policy:hover {
	background: #0055dd;
}
