.carousel-wrapper {
    position: relative;
    left: 0;
    width: 100%;
    .carousel-scroll {
        display: flex;
        flex-wrap: initial;
        //flex-direction: row;
        //white-space: nowrap;
        grid-column: 1/-1;
        grid-row: carousel;
        align-items: stretch;
        overflow-x: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: thin; /* Firefox */
        scrollbar-color: $gray-200 $white;

        &::-webkit-scrollbar {
            width: 4px; /* Remove scrollbar space */
            height: 4px;
            background: none; /* Optional: just make scrollbar invisible */
        }

        //&::-webkit-scrollbar-track {
        //    background: #f39;
        //}
        /* Optional: show position indicator in red */
        &::-webkit-scrollbar-thumb {
            background: $gray-200;
            border-radius: 6px;
            //border: 5px solid $white;
        }

        .scroll-item {
            flex: 0 0 auto;
            width: 40%;
            @include media-breakpoint-down(md) {
                width: 80%;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
        &.carousel-scroll-small-1 {
            .scroll-item {
                width: 259px;
                @include media-breakpoint-down(md) {
                    width: 50%;
                }
                @include media-breakpoint-down(sm) {
                    width: 50%;
                }
            }
        }

    }

}

.carousel-indicators {
    button:not(.active) {
        background-color: $white !important;
    }
}

@media (max-width: 890px) {
    .scrolling-content {
        max-width: 97vw !important;
    }
}
