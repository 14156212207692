.news {
	.news-list-view {
		.news-list-item {
			.news-list-item-border {
				padding-bottom: 50px;
				margin-bottom: 50px;
				border-bottom: 1px solid $gray-lighter;
			}
			.extra {
				margin-bottom: 5px;
			}
			.lead {
				font-size: $font-size-base;
			}
		}
	}
	.news-list-view-100 {
		.glyphicon {
			color: $text-color;
		}
		&.slick-slider {
			padding-bottom: 0;
			.slick-dots {
				bottom: -46px;
			}
		}
		.news-list-item {
			.article {
				text-align: center;
				//$media (max-width: $screen-sm-max) {
                @include media-breakpoint-down(md) {
					width: 80%;
					margin: 0 auto;
				}
				.news-list-item-header {
					padding-bottom: 0;
					line-height: 1.4;
					font-weight: 700;
					a {
						color: $text-color;
						&.btn {
							margin-top: -1px;
							margin-left: 40px;
							color: #fff;
							padding-top: 4px;
							padding-bottom: 4px;
                            &:after {
                                padding-top: 4px;
                                padding-bottom: 4px;
                            }
							//$media (max-width: $screen-sm-max) {
                            @include media-breakpoint-down(md) {
								margin-top: 10px;
								margin-left: 0;
								display: inline-block;
								margin: 0 auto;
								width: 50%;
							}
							//$media (max-width: $screen-xs-max) {
                            @include media-breakpoint-down(sm) {
								margin-top: 10px;
								margin-left: 0;
								display: inline-block;
								margin: 0 auto;
								width: 75%;
							}
						}
					}
				}
			}
		}
	}
	//.news-list-view-101 {
	//	span.info-bg {
	//		position: absolute;
	//		z-index: 9;
	//		top: 24px;
	//		left: 24px;
	//	}
	//	.news-list-view-teaserinfo {
	//		p {
	//			margin-bottom: 0;
	//		}
	//	}
	//	.news-list-item {
	//		background-color: $gray-lighter;
	//		background-repeat: no-repeat;
	//		background-position: right;
	//		background-size: cover;
	//		//height: 450px;
	//		overflow: hidden;
	//		a {
	//			display: block;
	//			width: 100%;
	//			height: 100%;
	//			.news-list-item-bg {
	//				position: absolute;
	//				width: 100%;
	//				height: 100%;
	//				top: 0;
	//				left: 0;
	//				background-color: rgba(42,43,47,0);
	//				z-index: 0;
	//				@include transition-theme-2(background-color, $teaser-main-duration-time, ease, $teaser-main-transition-time);
	//			}
	//			.news-list-item-content {
	//				padding: 0 25px;
	//				position: absolute;
	//				bottom: 0;
	//				height: 100px;
	//				width: 100%;
	//				z-index: 1;
	//				h1, h2, h3, h4, h5,
	//				h1 a, h2 a, h3 a, h4 a, h5 a {
	//					color: $text-color-lighten;
	//					font-size: $font-size-large-3;
	//				}
    //
	//				p {
	//					-webkit-transform: translate3d(0,0,0);
	//					transform: translate3d(0,0,0);
	//					font-size: $font-size-large;
	//					color: #fff;
    //
	//					@include transition-theme-2(all, $teaser-main-duration-time, ease, $teaser-main-transition-time);
	//					&.news-title {
	//						font-size: $font-size-large-1;
	//						font-weight: 700;
	//						text-transform: uppercase;
	//						margin-bottom: 0;
	//						a {
	//							color: #fff;
	//						}
	//					}
	//					&.news-divider {
	//						display: block;
	//						width: 50px;
	//						margin-bottom: 15px;
	//						padding-bottom: 15px;
	//						border-bottom: 2px solid $primary;
	//						height: 1px;
	//					}
	//					&.news-teaser {
	//						opacity: 0;
	//						@include transition-theme-2(all, $teaseropacity-main-duration-time, ease, $teaseropacity-main-transition-time);
	//					}
	//				}
	//			}
	//			&:after {
	//				//content: ' ';
	//				//position: absolute;
	//				//width: 100%;
	//				//height: 100%;
	//				//top: 0;
	//				//left: 0;
	//				//background-color: rgba(42,43,47,0.50);
	//				//z-index: 0;
	//			}
	//			&:hover {
	//				.news-list-item-bg {
	//					background-color: rgba(42,43,47,0.75);
	//					@include transition-theme-2(background-color, $teaser-main-duration-time, ease, $teaser-main-transition-time);
	//				}
	//				.news-list-item-content {
	//					p {
	//						-webkit-transform: translate3d(0,-140px,0);
	//						transform: translate3d(0,-140px,0);
	//						@include transition-theme-2(all, $teaser-main-duration-time, ease, $teaser-main-transition-time);
	//						//$media (max-width: $screen-lg) {
    //                        @include media-breakpoint-down(xl) {
	//							-webkit-transform: translate3d(0,-100px,0);
	//							transform: translate3d(0,-100px,0);
	//						}
	//						//$media (max-width: $screen-md) {
    //                        @include media-breakpoint-down(lg) {
	//							-webkit-transform: translate3d(0,-200px,0);
	//							transform: translate3d(0,-200px,0);
	//						}
	//						//$media (max-width: $screen-sm) {
    //                        @include media-breakpoint-down(md) {
	//							-webkit-transform: translate3d(0,-150px,0);
	//							transform: translate3d(0,-150px,0);
	//						}
	//						//$media (max-width: $screen-xs) {
    //                        @include media-breakpoint-down(sm) {
	//							-webkit-transform: translate3d(0,-80px,0);
	//							transform: translate3d(0,-80px,0);
	//						}
	//						&.news-divider,
	//						&.news-teaser {
	//							opacity: 1;
	//							@include transition-theme-2(all, $teaseropacity-main-duration-time, ease, $teaseropacity-main-transition-time);
	//						}
	//					}
	//				}
	//			}
	//		}
	//	}
	//}
	&.news-single {
		margin-top: 108px;
		.article {
			h1 {
				font-size: $h2-font-size;
				padding-bottom: 30px;
			}
			.extra {
				margin-bottom: 5px;
			}
			.thumbnail {
				border: 0;
			}
			.news-related {
				margin-top: 40px;
				margin-bottom: 40px;
			}
		}
	}
}
