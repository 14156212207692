@import 'variablesBootstrap';

$sideBarWidth: 365px;
$sideBarWidth-xl: 365px;
$sideBarWidth-lg: 320px;
$sideBarWidth-md: 320px;
$sideBarWidth-sm: 320px;

$sideBarMargin: 8.75rem;
$sideBarMargin-xl: 8.75rem;
$sideBarMargin-lg: 0px;
$sideBarMargin-md: 0px;
$sideBarMargin-sm: 0px;

//@include media-breakpoint-down(xl) {
//    $sideBarWidth: 21%;
//    $sideBarMargin: 10%;
//}

//
// Colors
//

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 20%); // #222
$gray-dark:              #383838;
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             #a9a8a8;
$gray-lighter:           #e9e9e9;

$color-white:							#fff;
$color-border:						$gray-700;
$color-gray-lighter-2:		#ececec;

$text-color: $body-color;
$text-color-lighten:			#fff;
$text-color-gray-light:		#878787;
$text-color-gray-lighter: $color-gray-lighter-2;

$elementbackground-gray:		$gray-lighter;

// DIV
$font-size-large: $font-size-lg;


//
// Diverse settings for padding and margin of
// e.g. elements
// --------------------------------------------------
$element-padding:					25px;
$element-padding-2x:			floor($element-padding*2);


// transistions

$nav-main-transition-time:		50ms;
$nav-main-duration-time:		300ms;
$link-hover-transition-time:	500ms;

$teaser-main-transition-time:	100ms;
$teaser-main-duration-time:		300ms;
$teaseropacity-main-transition-time:	100ms;
$teaseropacity-main-duration-time:		550ms;

// colors
$background-color-oddeven:	lighten(#000, 85%);


//
// Font sizes
//
$font-size-large-1:	$font-size-lg * 1.15;
$font-size-large-2:	$font-size-lg * 1.4;
$font-size-large-3:	$font-size-lg * 1.6;
$font-size-large-4:	$font-size-lg * 1.8;
$font-size-large-5:	$font-size-lg * 2;
$font-size-large-6:	$font-size-lg * 2.5;
$font-size-large-7:	$font-size-lg * 3;
$font-size-large-8:	$font-size-lg * 4;
$font-size-large-9:	$font-size-lg * 5;
$font-size-large-10: $font-size-lg * 6;

$font-size-small-1: $font-size-sm * 0.85;
$font-size-small-2: $font-size-sm * 0.8;


// font sizes for mixin (Util/_text.scss)
$fontsize: $font-size-base;
$fontsizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$fontsizes: map-merge(
        (
            1: ($fontsize * 1.17),
            2: ($fontsize * 1.375),
            3: ($fontsize * 1.75),
            4: ($fontsize * 2),
            5: ($fontsize * 2.25),
            6: ($fontsize * 2.5),
            7: ($fontsize * 2.75),
            8: ($fontsize * 3),
            9: ($fontsize * 3.25),
            10: ($fontsize * 3.5),
            11: ($fontsize * 3.75),
            12: ($fontsize * 4),
            13: ($fontsize * 4.25),
            14: ($fontsize * 4.5),
            15: ($fontsize * 4.75),
            16: ($fontsize * 5),
            17: ($fontsize * 5.25),
            18: ($fontsize * 5.75),
            19: ($fontsize * 6),
            20: ($fontsize * 6.25),
            21: ($fontsize * 6.5),
            22: ($fontsize * 6.75),
            23: ($fontsize * 7),
            24: ($fontsize * 7.25),
            25: ($fontsize * 7.5),
            26: ($fontsize * 7.75),
            27: ($fontsize * 8),
            28: ($fontsize * 8.5),
            29: ($fontsize * 9),
            30: ($fontsize * 9.5),
            31: ($fontsize * 10),
            32: ($fontsize * 18.75),
        ),
        $fontsizes
);


$fontsizes-small: ();
// stylelint-disable-next-line scss/dollar-variable-default
$fontsizes-small: map-merge(
        (
            1: ($fontsize * 0.95),
            2: ($fontsize * 0.90),
            3: ($fontsize * 0.85),
            4: ($fontsize * 0.80),
            5: ($fontsize * 0.75),
            6: ($fontsize * 0.70),
            7: ($fontsize * 0.65),
            8: ($fontsize * 0.60),
            9: ($fontsize * 0.55),
            10: ($fontsize * 0.50),
        ),
        $fontsizes-small
);

// ---------------------------------------------------------------------------------------------------------------------------------------------------------------- //

$border-thickness: ();
$border-thickness: map-merge(
    (
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px,
        6: 6px,
        7: 7px,
        8: 8px,
        9: 9px,
        10: 10px,
    ),
    $border-thickness
);

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer * 0.75,
    4: $spacer,
    5: $spacer * 1.25,
    6: $spacer * 1.5,
    7: $spacer * 1.75,
    8: $spacer * 2,
    9: $spacer * 2.25,
    10: $spacer * 2.5,
    11: $spacer * 2.75,
    12: $spacer * 3,
    13: $spacer * 3.25,
    14: $spacer * 3.5,
    15: $spacer * 3.75,
    16: $spacer * 4,
    17: $spacer * 4.25,
    18: $spacer * 4.5,
    19: $spacer * 4.75,
    20: $spacer * 5,
    21: $spacer * 5.25,
    22: $spacer * 5.5,
    23: $spacer * 5.75,
    24: $spacer * 6,
    25: $spacer * 6.25,
    26: $spacer * 6.5,
    27: $spacer * 6.75,
    28: $spacer * 7,
    29: $spacer * 7.25,
    30: $spacer * 7.5,
    35: $spacer * 8.75,
    40: $spacer * 10,
    45: $spacer * 11.25,
    50: $spacer * 12.5,
    55: $spacer * 13.25,
    60: $spacer * 14.5,
);
