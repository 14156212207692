footer.page-footer {
    margin-top: 2rem;
	.footer-container {
		letter-spacing: 2px;


		p {
			margin-bottom: 50px;
			a {
				color: $text-color-gray-lighter;
			}
		}
		div[class*="col-"] {
			.container-fluid {
				width: 100%;
				padding: 0;
			}
		}
		.footer-socialnavigation {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					margin: 0;
					padding: 0;
					float: left;
					margin-right: 25px;
					a {
						display: block;
						color: $text-color-lighten;
					}
				}
			}
		}

        .servicenavigation {
			ul,
			ul li {
				list-style: none;
				margin: 0;
				padding: 0;
			}
			ul {
				li {
					a {
					}
				}
			}
		}
		.tx-powermail {
			margin-top: 8px;
		}

	}
    .footer-bottom {
        padding: 50px 0;
        background-color: $gray-darker;
        line-height: 1.2;
        > .container-fluid {
            > div[class*="col-"] {
                padding-left: 0;
                padding-right: 0;
                display: inline-block;
            }
        }
        nav.footernavigation {
            //$media (max-width: $screen-sm-max) {
            @include media-breakpoint-down(lg) {
                display: inline-block;
                padding: 30px 0;
            }

            //$media (max-width: $screen-md-max) {
            @include media-breakpoint-down(xl) {
                margin-bottom: 35px;
            }
            ul,
            ul li {
                list-style: none;
                margin: 0;
                padding: 0;
            }
            ul {
                display: flex;
                justify-content: center;
                //$media (max-width: $screen-md-max) {
                @include media-breakpoint-down(xl) {
                    display: inline-block;
                }
                li {
                    float: left;
                    border-right: 1px solid $text-color-lighten;
                    padding-right: 10px;
                    margin-right: 10px;
                    //$media (max-width: $screen-sm-max) {
                    @include media-breakpoint-down(md) {
                        float: none;
                        border-right: 0;
                    }
                    &:last-of-type {
                        border: 0;
                        margin: 0;
                        padding: 0;
                    }
                    a {
                        color: $text-color-lighten;
                        //$media (max-width: $screen-sm-max) {
                        @include media-breakpoint-down(md) {
                            display: block;
                            padding: 5px 0;
                        }
                    }
                }
            }
        }
        nav.languagenavigation {
            margin-top: -10px;
            position: relative;
            ul,
            ul li {
                list-style: none;
                margin: 0;
                padding: 0;
            }
            ul {
                //background-color: $gray-dark;
                //float: right;
                //$media (max-width: $screen-sm-max) {
                @include media-breakpoint-down(md) {
                    //float: left;
                }
                li {
                    color: #fff;
                    cursor: pointer;
                    padding: 10px;
                    &:after {
                        display: inline-block;
                        font-family: 'Font Awesome 6 Free';
                        font-weight: 900;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: "\f077";
                        padding-left: 60px;
                    }
                    ul {
                        display: none;
                        position: absolute;
                        width: 100%;
                        right: 0;
                        bottom: 36px;
                        //$media (max-width: $screen-sm-max) {
                        @include media-breakpoint-down(md) {
                            //left: 15px;
                            right: inherit;
                        }
                        li {
                            padding: 0;
                            &:after {
                                display: none;
                                content: "";
                            }
                            a {
                                display: block;
                                color: #fff;
                                padding: 10px;
                                //padding-right: 78px;
                            }
                        }
                    }
                }
            }
        }
    }
}
