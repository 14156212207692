/* CSS Document */
.text-normal {
	text-transform: none;
}
.tx-ids-references {
	.well {
		border: 0;
		box-shadow: none;
	}

	a {
		//color: lighten($gray-base,50%);
	}
	a:hover, a:active, a:focus {
		//color: $brand-primary;
	}

	a.back {
		display: block;
		margin-top: $line-height-base*2;
		color: $primary;
		text-transform: uppercase;
	}

	address {
		margin-bottom: 12px;
	}

	.float-left {
		float: left;
	}
	.float-right {
		float: right;
	}

	i.fa {
		text-align: center;
		width: 20px;
	}
	i.fa.fa-at {
		width: auto;
	}

	.isotope-grid .item {
		height: auto;
		margin-bottom: 0;
		min-height: 200px;
	}
	.row-result {
		/*
		padding: 15px 0;
		*/
	}
	.search-form {
		background-color: $gray-dark;
		padding: $line-height-base*1.5 0;

		p {
			color: #fff;
			margin-bottom: 10px;
		}
		.btn-default {
			width: 100%;
			background-color: $primary;
			border-color: #fff;
			color: #fff;
		}
		.row {
			margin-bottom: 0;
		}
		.form-control-feedback {
			display: none;
		}
	}

	.border-box {
		border: 1px solid $gray-light;
		background-color: #fff;

		min-height: 233px;
		margin-top: 15px;
		margin-bottom: 15px;
		.border-box-content {
			padding: 20px;
			background-color: $gray-lighter;
			h4 {
				margin-top: 0;
				height: 65px;
				overflow: hidden;
				line-height: 25px;
				padding-bottom: 15px;
			}
			address {
				height: 65px;
				overflow: hidden;
			}
		}
		.border-top {
			display: inherit;
			margin: 0 20px;
			padding-top: 12px;
			padding-bottom: 12px;
			a {
				font-weight: 700;
			}
		}
	}

	.col-gvl {
		.border-box-content {
			height: 231px;
			overflow: hidden;
			address {
				height: inherit;
			}
		}
	}

	.form-inline .form-control {
		width: 100%;
	}

	.filters {
		padding: 15px 0;
		background-color: #fff;
		text-align: center;

		.button-group {
			display: inline;

			.button {
				background-color: #fff;
				border: 0 none;
			}
			.button.is-checked {
				color: $primary;
			}
		}

		.btn-group {
			display: inline;

			.btn.is-checked {
				color: $primary;
			}
			.btn-filter {
				background-color: #fff;
				border: 0 none;
			}
		}

		.btn-group-vertical {
			display: inline;
		}

		.btn-toolbar .btn {
			float: none;
		}
		.btn-toolbar .btn-group {
			float: none;
		}
		.btn-toolbar .input-group {
			float: none;
		}
	}
	.location-map {
	  height: 500px;
	  width: 100%;
	}
	.location-search-map {
	  height: 500px;
	  width: 100%;
	  margin-bottom: 24px;
	}
	.row-route > [class*="col-"] {
		padding-bottom: 0;
		margin-bottom: 0;
		margin-top: 24px;
	}
	.row-back > [class*="col-"] {
		margin-bottom: 0;
	}
	.row-back {
		.glyphicon {
			font-size: 0.8rem;
		}
	}
}
.location-show {
	.row-eq-height .row {
		margin-bottom: 0;
	}
	.well.bg-gray-03.full-width {
		padding-bottom: 0;
	}
	.well.bg-white {
		margin: 0;
	}
	.row-eq-height {
		margin-bottom: $line-height-base;
	}
}
.owner-show {
	padding-top: $line-height-base;

	.row-eq-height .row {
		margin-bottom: 0;
	}
	.well.bg-gray-03.full-width {
		padding-bottom: 0;
	}
	.well.bg-white {
		margin: 0;
	}
	.row-eq-height {
		margin-bottom: $line-height-base;
	}

	ul {
		-moz-column-count: 2;
		-moz-column-gap: 15px;
		-webkit-column-count: 2;
		-webkit-column-gap: 15px;
		column-count: 2;
		column-gap: 15px;
		list-style: outside none none;
		padding: 0;
	}
}

#reference_map {
  height: 100%;
  width: 100%;
  z-index: 0; /* Set z-index to 0 as it will be on a layer below the contact form */
  position: absolute;
}

#p62 {
	.news {
		.row {
			margin-top: 48px;
		}
	}
}
