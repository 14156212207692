.tx-ids-products {
margin-top: 50px;
}
.tx-ids-products,
.product-details-listProducts {
	.item {
		padding-bottom: $grid-gutter-width;
		.item-wrapper {
			border: 1px solid $gray-light;

			.item-image {
				background-color: $gray-lighter;
				padding: 20px 0;
				&.image-center {
					text-align: center;
				}
				img {
					margin: 0 auto;
				}
			}
			.item-text {
				padding: 20px;
				font-weight: 700;
				height: 92px;
				overflow: hidden;
			}
			.item-footer {
				border-top: 4px double $gray-lighter;
				padding: 10px 0;
				margin: 0 20px;
			}
			a .item-text {
				color: $text-color;
			}
		}
	}
	&.isotope-grid {
		.item {
			min-height: 390px;
		}
		img {
			max-height: 300px;
		}
	}
}
.product-details-listProducts {
	> .row {
		margin-left: -($grid-gutter-width);
		margin-right: -($grid-gutter-width);
	}
}
.product-show {
	margin-top: 184px;
	h2 {
		margin-bottom: 50px;
	}
	.ids-products-categoryproducts {
		background-color: $elementbackground-gray;
		padding-top: $element-padding-2x;
		padding-bottom: $element-padding-2x;
		h2 {
			margin-bottom: 0;
		}
		.product-category {
			margin-bottom: 80px;
		}
	}
}
.tx-ids-products-show {
	margin-top: 80px;
	margin-bottom: 80px;
	.product-eeklasse {
		position: absolute;
		left: 30px;
		top: 10px;
		//bottom: 100px;
	}
	.actions {
		margin-top: 50px;
	}
	.idsproducts-actions {
		.btn {
			width: 100%;
			margin-bottom: 10px;
		}
	}
	.panel-group {
		.panel {
			border: 0;
			margin: 0 !important;

			.panel-heading {
				margin-top: -1px !important;
				background: none;
				border-top: 1px solid $gray-light;
				border-bottom: 1px solid $gray-light;
				padding: 15px 0;
				font-weight: 700;
				i.fa {
					color: $primary;
				}
				a.collapsed i.fa:before {
					content: "\f067";
				}
			}
			&:first-of-type .panel-heading {

			}
			.panel-body {
				border: 0;
				padding-left: 0;
				padding-right: 0;
				table.table-condensed td {
					border: 0;
				}
			}
			#panelCharacteristics {
				ul.pictograms,
				ul.pictograms li {
					list-style: none;
					margin: 0;
					padding: 0;
				}
				ul.pictograms {
					li {
						float: left;
						margin-right: 20px;
					}
				}
			}
		}
	}
	.ids-products-imageslider {
		.slick-list {

		}
		.slick-track {
			.image-item {
				float: left;
			}
		}
		.image-item-description {
			position: absolute;
			left: 215px;
			bottom: 35px;
			font-weight: bold;
			font-size: 0.85em;
		}
		ul.slick-dots {
			position: relative !important;
			display: inline-block;
			bottom: 0px;
			margin-top: 40px;
			li {
				width: 16.6667%;
				height: 70px;
				padding: 0 $grid-gutter-width/2;
				margin: 0;
				text-align: center;
			}
		}
	}
	.ids-prdocucts-imagetext1 {
		font-weight: bold;
	}
	.ids-prdocucts-imagetext1,
	.ids-prdocucts-imagetext2 {
		padding: 0 $grid-gutter-width/2;
	}
}


.pagets__special_start {
	.tx-ids-products {

	}
}

.tx-ids-products-isotope {
	.filters {
		text-align: center;
		//$media(max-width: $screen-md-max) {
        @include media-breakpoint-down(lg) {
			margin-bottom: 40px;
		}
	}
	.btn-toolbar {
		display: flex;
    justify-content: center;
		margin-top: 50px;
		margin-bottom: 20px;
		//$media(max-width: $screen-md-max) {
        @include media-breakpoint-down(lg) {
			display: initial;
			margin-left: 0;
		}
		.btn-group {
			margin: 0;
			&:not(first-of-type) {
				margin-left: -1px;
				//$media(max-width: $screen-md-max) {
                @include media-breakpoint-down(lg) {
					margin-left: 0;
					float: none;
					display: inline;
				}
			}
			button {
				background-color: transparent;
				border-color: $gray-light;
				padding: 4px 12px;
				font-size: $font-size-sm;
				&:hover {
					background-color: $primary;
					color: #fff;
				}
				&.is-checked {
					background-color: $primary;
					color: #fff;
				}
				//$media(max-width: $screen-md-max) {
                @include media-breakpoint-down(lg) {
					border: 0;
					padding: 0;
					margin: 0 8px;
					float: none;
					&:hover {
						background: none;
						color: $primary;
					}
					&.is-checked {
						background: none;
						color: $primary
					}
				}
			}
			&:first-of-type button {
				border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius;
			}
			&:last-of-type button {
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
			}
		}
	}
}
