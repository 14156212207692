//@each $breakpoint in map-keys($grid-breakpoints) {
//    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
//        @each $size, $length in $rounded-widths {
//            @if $size != 0 {
//                .rounded#{$infix}-#{$size} { border-radius: $length !important; }
//            }
//        }
//    }
//}

@each $size, $length in $border-thickness {
    @if $size != 0 {
        .border-top-width-#{$size} { border-top-width: $length !important; }
        .border-right-width-#{$size} { border-right-width: $length !important; }
        .border-bottom-width-#{$size} { border-bottom-width: $length !important; }
        .border-left-width-#{$size} { border-left-width: $length !important; }
    }
}



@each $name, $color in $blues {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
@each $name, $color in $indigos {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
@each $name, $color in $purples {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
@each $name, $color in $pinks {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
@each $name, $color in $reds {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
@each $name, $color in $oranges {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
@each $name, $color in $yellows {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
@each $name, $color in $greens {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
@each $name, $color in $teals {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
@each $name, $color in $cyans {
    .bg-#{$name} {
        background-color: $color !important;
    }
}
