.tx-ids-product {
    .ids-product-documents {
        .select-section {
            padding-right: 25px;

        }
        .result-section {
            padding-left: 25px;
        }
        header {
            margin-bottom: 20px;
        }
        .documents-wrapper {
            position: relative;
            margin-bottom: 60px;
            &.documents-filter.inactive {
                opacity: 0.25;
            }
            .reset-fulltext {
                position: absolute;
                margin-left: 12px;
                display: none;
            }
        }
        div[class*="section-"] {
            position: relative;
            background-color: $gray-lighter;
            padding: 25px;
            margin-bottom: 0;
            &.inactive {
                opacity: 0.25;
            }
            div[class*="section-"] {
                padding: 0;
            }
        }
        div.section-language {
            margin-bottom: 60px;
        }
        div.section-document {
            background-color: transparent;
            position: relative;
            padding: 0;
            .section-document-info {
                background-color: transparent;
            }
            .section-document-data {
                background-color: transparent;
                padding: 25px;

                .productname {
                    margin-bottom: 25px;
                }
                .documents-item-wrapper {
                    margin-bottom: 40px;
                    h4 {
                        margin-bottom: 20px;
                    }
                    .row {
                        border-bottom: 1px solid $gray-lighter;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        a {
                            padding: 10px 0;
                        }
                        //&:last-of-type {
                        //    margin-bottom: 40px;
                        //}
                        .filesize {
                            text-align: right;
                            //@media(max-width: @screen-xs-max) {
                            @include media-breakpoint-down(sm) {
                                text-align: left;
                            }
                        }
                    }
                }
            }

        }
    }
    .preloader-small {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 128px;
        top: 0;
        left: 0;
        background-image: url(../Images/Icons/preloader.gif);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: rgba(255,255,255,0.8);
    }
}
